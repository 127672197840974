import React, { useState, useEffect } from 'react';
import { Container, Typography, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, Palette, PaletteColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Api, LicenseResponseDto, LicenseStatus } from '../../apiClient/Api';
import { useApplicationContext } from 'ApplicationContext';
import dayjs from 'dayjs';

const AdminRequestedLicenses: React.FC = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState<LicenseResponseDto[]>([]);
  const [showUnapprovedOnly, setShowUnapprovedOnly] = useState(false);
  const context = useApplicationContext();
  const theme = useTheme();

  useEffect(() => {
    const fetchRequests = async () => {
      const response = await context.api.service.serviceGetAllRequestedLicenses();
      setRequests(response.data);
    };

    fetchRequests();
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnapprovedOnly(event.target.checked);
  };

  /**
   * Handles the generation of a license for a specific request and updates the row with the returned data.
   * 
   * @param requestId - The ID of the request
   */
  const handleGenerateLicense = async (requestId: string) => {
    const response = await context.api.service.serviceGenerateAndStoreLicense(requestId);
    setRequests((prevRequests) =>
      prevRequests.map((request) =>
        request.id === requestId ? response.data : request
      )
    );
  };

  /**
   * Gets the translated status text based on the request status.
   * 
   * @param status - The status of the license request
   * @returns The translated status text
   */
  const getStatusText = (status: LicenseStatus): string => {
    switch (status) {
      case LicenseStatus.Ordered:
        return t('adminRequestedLicenses.statusPending');
      case LicenseStatus.LicenseGenerated:
        return t('adminRequestedLicenses.statusApproved');
      case LicenseStatus.Rejected:
        return t('adminRequestedLicenses.statusRejected');
      case LicenseStatus.Revoked:
        return t('adminRequestedLicenses.statusRevoked');
      default:
        return t('adminRequestedLicenses.statusUnknown');
    }
  };

  /**
   * Gets the row color based on the request status.
   * 
   * @param status - The status of the license request
   * @returns The row color
   */
  const getRowColor = (status: LicenseStatus): PaletteColor => {
    switch (status) {
      case LicenseStatus.Rejected:
        return theme.palette.error;
      case LicenseStatus.Ordered:
        return theme.palette.warning;
      case LicenseStatus.LicenseGenerated:
        return theme.palette.success;
      case LicenseStatus.Revoked:
        return theme.palette.error;
      default:
        return theme.palette.warning;
    }
  };

  const getTextColor = (status: LicenseStatus): string => {
    switch (status) {
      case LicenseStatus.Rejected:
      case LicenseStatus.Revoked:
        return theme.palette.error.contrastText;
      case LicenseStatus.Ordered:
        return theme.palette.warning.contrastText;
      case LicenseStatus.LicenseGenerated:
        return theme.palette.success.contrastText;
      default:
        return theme.palette.warning.contrastText;
    }
  };

  const filteredRequests = showUnapprovedOnly
    ? requests.filter(request => request.status == LicenseStatus.Ordered)
    : requests;

  return (
    <Container sx={{minWidth: "1400px"}}>
      <Typography variant="h4" gutterBottom>
        {t('adminRequestedLicenses.title')}
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={showUnapprovedOnly} onChange={handleFilterChange} />}
        label={t('adminRequestedLicenses.showUnapprovedOnly')}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>{t('adminRequestedLicenses.status')}</TableCell>

              <TableCell width={170}>{t('adminRequestedLicenses.requestId')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.userName')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.supportCode')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.supportContract')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.description')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.requestedAt')}</TableCell>
              <TableCell>{t('adminRequestedLicenses.licenseGeneratedAt')}</TableCell>
              <TableCell width={200}>{t('adminRequestedLicenses.licenseCode')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.map((request) => (
              <TableRow key={request.id} sx={{ backgroundColor: getRowColor(request.status ?? LicenseStatus.InCart).main }}>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>
                  {getStatusText(request.status ?? LicenseStatus.InCart)}
                </TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.hostId}</TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.userName}</TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.supportCodeId}</TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.supportContract}</TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.description}</TableCell>
                <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{dayjs(request.requestedAt).format('DD.MM.YYYY')}</TableCell>
                {request.status == LicenseStatus.LicenseGenerated ? (
                  <>
                    <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{dayjs(request.licenseGeneratedAt).format('DD.MM.YYYY')}</TableCell>
                    <TableCell sx={{ color: getTextColor(request.status ?? LicenseStatus.InCart) }}>{request.licenseCode}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell colSpan={2}>
                      <Button fullWidth variant="contained" color="primary" onClick={() => handleGenerateLicense(request.id!)}>
                        {t('adminRequestedLicenses.generateLicense')}
                      </Button>
                    </TableCell>
                  </>
                )}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminRequestedLicenses;
