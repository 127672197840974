

export type TQuickSupportType = {
    type: number;
    name: string;

    displayPayment: boolean
    needValidId: boolean
}

export const QuickSupportTypes: TQuickSupportType[] = [
    {type: 1,  name: "not_specified", displayPayment: false, needValidId: false},
    {type: 2, name: "support_appointment", displayPayment: true, needValidId: true},
    {type: 3, name: "disruption_report" , displayPayment: false, needValidId: true},
    {type: 4, name: "bug_report" , displayPayment: false, needValidId: true},
    {type: 5, name: "deadlock" , displayPayment: true, needValidId: true},
    {type: 6, name: "about_support_system" , displayPayment: false, needValidId: false},
    {type: 7, name: "sales" , displayPayment: false, needValidId: false},
];

