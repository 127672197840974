import React from 'react';
import { useTheme } from '@mui/material/styles';

const PhonePlaceholder = () => {
  const theme = useTheme();
  const foregroundColor = theme.palette.text.primary;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200" width="100" height="200">
      {/* Telefon */}
      <rect x="10" y="10" width="80" height="180" fill="none" stroke={foregroundColor} strokeWidth="2" rx="15" ry="15" />

      {/* Bildschirm */}
      <rect x="20" y="30" width="60" height="130" fill="none" stroke={foregroundColor} strokeWidth="1" rx="5" ry="5" />

      {/* Home-Button */}
      <circle cx="50" cy="175" r="5" fill="none" stroke={foregroundColor} strokeWidth="2" />

      {/* Diagonale Linien */}
      <line x1="30" y1="50" x2="70" y2="110" stroke={foregroundColor} strokeWidth="1" />
      <line x1="70" y1="50" x2="30" y2="110" stroke={foregroundColor} strokeWidth="1" />

      {/* Regel der Drittel Linien */}
      <line x1="40" y1="30" x2="40" y2="160" stroke={foregroundColor} strokeWidth="1" />
      <line x1="60" y1="30" x2="60" y2="160" stroke={foregroundColor} strokeWidth="1" />
      <line x1="20" y1="70" x2="80" y2="70" stroke={foregroundColor} strokeWidth="1" />
      <line x1="20" y1="110" x2="80" y2="110" stroke={foregroundColor} strokeWidth="1" />
    </svg>
  );
};

export default PhonePlaceholder;
