import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, IconButton, InputLabel,
    NativeSelect,
    TextField, Toolbar
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../../ApplicationContext";
import {useSnackbar} from "notistack";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {GetUserData} from "../../../apiClient/Api";
import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {Close, Edit, Filter, FilterAlt, FilterAltOff, Groups, PersonAdd, QrCode2, Support} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import * as string_decoder from "string_decoder";
import Typography from "@mui/material/Typography";
import {format, parseISO} from "date-fns";

export interface ISupportContractUsersComponent {
    closeComponent: (created: boolean) => void
    supportId: string | undefined
}


export const SupportContractUsersComponent = (props: ISupportContractUsersComponent) => {


    const [users, setUsers] = useState<GetUserData[]>([])
    const [allUsers, setAllUsers] = useState<GetUserData[]>([])

    const [initialized, setInitialized] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [customer, setCustomer] = useState<string>("");

    const [expire, setExpire] = useState<Dayjs | null>(dayjs().add(1, 'year'));
    const [comment, setComment] = useState<string>("")
    const [addUser, setAddUser] = useState<boolean>(false)

    const context = useApplicationContext();
    const open = props.supportId != undefined;

    const {enqueueSnackbar} = useSnackbar();
    const reset = () => {
        setTitle("")
        setTitle("")
        setCustomer("")
        setComment("")
        setExpire(dayjs().add(1, 'year'))
        setInitialized(false);
    }

    useEffect(() => {
        if (props.supportId != undefined && !initialized) {

            loadContextUsers()

            context.api.supportContract.supportContractGetSupportContract(props.supportId).then(result => {
                const data = result.data
                setExpire(dayjs(data.expire!.toString()))
                setCustomer(data.customerName!)
                setTitle(data.title!)
                setComment(data.comment!)

            }).catch(context.apiError)
            setInitialized(true);
        }
    })


    function handleClose() {
        props.closeComponent(false);
        reset()

    }

    function handleSave() {

        // Update support contract
        if (props.supportId !== undefined) {
            props.closeComponent(false);
            reset()
        }
        // Create new support contract
        else {
            context.api.supportContract.supportContractAddSupportContract({
                //@ts-ignore
                fileTags: [],
                versionTags: [],
                title: title,
                customerName: customer,
                comment: comment,
                expire: expire?.toISOString()
            }).then(() => {
                props.closeComponent(false);
                reset()
            }).catch(context.apiError)
        }

    }

    const loadContextUsers = () => {
        if(props.supportId){
            context.api.supportContract.supportContractGetSupportContractUsers(props.supportId).then(result => {
                const data = result.data;
                setUsers(data)
            }).catch(context.apiError);
        }
    }

    const loadAllUsers = () => {
        context.api.user.userListUsers().then(result => {
            const data = result.data;
            setAllUsers(data)
        }).catch(context.apiError);
    }

    const EditToolbar = () => <Button onClick={() => {
        setAddUser(true)
        loadAllUsers();
    }} startIcon={<FilterAltOff/>}>Alle Nutzer anzeigen</Button>

    const EditToolbarFinish = () => <Button onClick={() => {
        setAddUser(false)
        loadContextUsers();
    }} startIcon={<FilterAlt/>}>Nutzer im Vertrag anzeigen</Button>



    const addUserById = (id : string) => {
        context.api.supportContract.supportContractAddUserToSupportContract(props.supportId!, id).then(() => {
            loadContextUsers()
        }).catch(context.apiError)
    }

    const removeUserById = (id : string) => {
        context.api.supportContract.supportContractRemoveUserToSupportContract(props.supportId!, id).then(() => {
            loadContextUsers()
        }).catch(context.apiError)
    }

    const actionsAdd = (params: GridRowParams) => [
        <GridActionsCellItem label={"Nutzer löschen"} disabled={(context.userData!.userGroup ?? 4) >= 2}
                             onClick={() => {
                                 if(users.find(u => u.id == params.row.id)){
                                     removeUserById(params.row.id)

                                 }
                                 else{
                                  addUserById(params.row.id)
                                 }
                             }} icon={users.find(u => u.id == params.row.id) ?  <DeleteIcon/> : <PersonAdd/>}/>,
    ]

    const actionsRemove = (params: GridRowParams) => [
        <GridActionsCellItem label={"Nutzer löschen"} disabled={(context.userData!.userGroup ?? 4) >= 2}
                             onClick={() => {
                                removeUserById(params.row.id)
                             }} icon={   <DeleteIcon/>}/>,
    ]

    const columns: GridColDef[] = [
        {
            field: "name", headerName: "Name", width: 180
        },
        {field: "eMail", headerName: "Mail", flex: 1},

        {
            field: "lastLogin",
            width: 130,
            headerName: "Letzter Login",
            valueFormatter: (params: GridValueFormatterParams<string | undefined>) => {
                if (params.value == undefined) {
                    return "Nicht eingeloggt"
                }
                const date = parseISO(params.value.toString()) // not MM-DD-YY
                return format(date, "dd.MM.yyyy")
            }

        },
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: addUser ? actionsAdd : actionsRemove
        },

    ]
    return <Dialog open={open} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Nutzer Bearbeiten - Support ID: {props.supportId}
                </Typography>

            </Toolbar>
        </AppBar>
        <DialogTitle></DialogTitle>
        <DialogContent>
            <DialogContentText>Nutzer zum Support Vertrag {title} ({customer}) hinzufügen. </DialogContentText>
            <DataGrid
                slots={{
                    toolbar: addUser ? EditToolbarFinish : EditToolbar,
                }}
                pageSizeOptions={[10]}
                rows={addUser ? allUsers : users}
                columns={columns}
                autoHeight={true}></DataGrid>
        </DialogContent>
    </Dialog>
}
