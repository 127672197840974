import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Paper, Typography, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { useApplicationContext } from 'ApplicationContext';
import { FileTypeLicenseDto, FileTypeVariantsDto } from 'apiClient/Api';
import { useParams, useNavigate } from 'react-router'; // Import useParams for URL parameters

/**
 * LicenseRequest component
 * 
 * This component renders a form for requesting licenses with a description field and license code input.
 * The input is formatted with separators (-) and automatically jumps to the next block.
 */
export const LicenseRequest: React.FC = () => {
    const { t } = useTranslation();
    const { supportId } = useParams<{ supportId: string }>(); // Get supportCode from URL
    const [licenseCode, setLicenseCode] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [modus, setModus] = useState<string | null>(null);
    const [key, setKey] = useState<string | null>(null);
    const [licenseGeneratorVersions, setLicenseGeneratorVersions] =useState<FileTypeLicenseDto[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined);
    const [variants, setVariants] = useState<FileTypeVariantsDto[]>([]);
    const context = useApplicationContext();
    const navigate = useNavigate();
    const descriptionRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        context.api.service.serviceGetLicenseVersions().then((data) => {
            setLicenseGeneratorVersions(data.data);
        }).catch((error) => {
            console.error(error);
        });
    },[]);

    useEffect(() => {
        setModus(null);
        if (selectedProduct) {
            setVariants(licenseGeneratorVersions.find((product) => product.id === selectedProduct)?.variants || []);
     
        } else {
            setVariants([]);
        }
    }, [selectedProduct, licenseGeneratorVersions]);

    /**
     * Fetches license data based on the provided license code, description, and modus.
     * 
     * @param licenseCode - The license code
     */
    const getLicenseData = useCallback(async (licenseCode: string, resetCode: boolean = false) => {

        if(selectedProduct){

            context.api.service.serviceRequestLicense(
                {
                    description: description,
                    supportCodeId: supportId!,
                    hostId: licenseCode,
                    fileTypeId: selectedProduct,
                    tag: modus            
                }
            ).then((data) => {
                //setKey(data.data);
                if (resetCode) {
                    setLicenseCode('');
                    setDescription('');
                    descriptionRef.current?.focus();
                } else {
                    navigate(`/licenses/${supportId}`);
                }
            }).catch((error) => {
                setKey(null);
            });
        }

    }, [selectedProduct, modus, description]);

    /**
     * Handles changes to the license code input.
     * 
     * @param value - The new license code value
     */
    const handleLicenseCodeChange = (value: string) => {
        setLicenseCode(value.toUpperCase());
        setKey(null); // Reset the license key when the input changes
    };

    /**
     * Determines if the order button should be enabled.
     * 
     * @returns {boolean} - True if the button should be enabled, false otherwise
     */
    const isOrderButtonEnabled = () => {
        return description !== '' && selectedProduct !== undefined && licenseCode.length === 14;
    };

    return (
        <Box width={1}>
            <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('licenseGenerator.title')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap:2, paddingBottom: 2 }}>


                <FormControl sx={{ flexGrow: 1 }}>
                        <InputLabel>{t('licenseGenerator.product')}</InputLabel>
                        <Select
                            defaultValue={undefined}
                            value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}
                            label={t('licenseGenerator.product')}
                        >
                            <MenuItem  value={undefined}>-</MenuItem>
                            {licenseGeneratorVersions.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
             
                {variants.length > 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, paddingBottom: 2 }}>
                        <FormControl sx={{ flexGrow: 1 }}>
                            <InputLabel>{t('licenseGenerator.variant')}</InputLabel>
                            <Select
                                value={modus}
                                onChange={(e) => setModus(e.target.value)}
                                label={t('licenseGenerator.variant')}
                            >
                                <MenuItem value={undefined}>{t('licenseGenerator.default')}</MenuItem>
                                {variants.map((variant) => (
                                    <MenuItem key={variant.tag} value={variant.tag}>
                                        {variant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                    <TextField
                        label={t('licenseGenerator.description')}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        inputRef={descriptionRef}
                    />


                <IMaskInput
                        mask="0000-0000-0000"
                        definitions={{
                            '0': /[A-Fa-f0-9]/
                        }}
                        value={licenseCode}
                        onAccept={(value: any) => handleLicenseCodeChange(value)}
                        overwrite
                        placeholder={t('licenseGenerator.code')}
                        style={{ flexGrow: 1, padding: '16.5px 14px', fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px' }}
                    />
               </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button 
                        variant="contained" 
                        onClick={() => getLicenseData(licenseCode)} 
                        disabled={!isOrderButtonEnabled()}
                    >
                        {t('licenseGenerator.addToOrder')}
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => getLicenseData(licenseCode, true)} 
                        disabled={!isOrderButtonEnabled()}
                    >
                        {t('licenseGenerator.addToOrderAndAddAdditional')}
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => navigate(`/licenses/${supportId}`)}
                    >
                        {t('licenseGenerator.backToOverview')}
                    </Button>
                    <Typography variant="body2" color="textSecondary">
                        {t('licenseGenerator.confirmOrderAllText')}
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

