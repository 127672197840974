import {
    Accordion, AccordionDetails, AccordionSummary,
    Avatar, Badge, Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    styled, TextField,
    Typography,
    useTheme
} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {DownloadFileButton} from "./DownloadFileButton";
import {useApplicationContext} from "../ApplicationContext";
import {
    EUserGroup,
    EVersionStage,
    GetVersionData,
    GetVersionDataCustomer,
    VersionVersionFileData
} from "../apiClient/Api";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useCallback, useState} from "react";
import {MuiMarkdown} from "mui-markdown";
import {DeleteFileDialg} from "./Admin/DeleteFileDialog";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faStars,
    faFileArrowDown,
    faUser,
    faTriangleExclamation,
    faDownload,
    faWandMagicSparkles,
    faCalendar,
    faTrash,
    faCaretDown
} from '@fortawesome/pro-solid-svg-icons'
import {useTranslation} from "react-i18next";

export interface VersionItemProps {
    data: GetVersionData | GetVersionDataCustomer
    reloadCallback?: () => void
}


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,

    textAlign: 'left',
    color: theme.palette.text.secondary,
}));


export const VersionItem = (props: VersionItemProps) => {
    const {t} = useTranslation();
    const changes = props.data.changes;
    const context = useApplicationContext();
    const theme = useTheme();
    const [downloadsOpen, setDownloadsOpen] = useState(false)
    const [sendMailToAllOpen, setSendMailToAllOpen] = useState(false)

    const [selectedFile, setSelectedFile] = useState<VersionVersionFileData | undefined>(undefined);
    const [selectedFileDelete, setSelectedFileDelete] = useState<string | undefined>(undefined);
    const [mailContent, setMailContent] = useState<string>("")
    const fileDeleted = useCallback((deleted: boolean) => {
        setSelectedFileDelete(undefined);


        if (deleted) {
            if (props.reloadCallback)
                props.reloadCallback();
        }
    }, [setSelectedFileDelete])

    const FontAwesomeListIcon = styled(ListItemIcon, {})({
        fontSize: 18,
        minWidth: 33,
        color: theme.palette.primary.light
    });

    const FontAwesomeListText = styled(ListItemText, {})({
        fontSize: 14
    });

    const isCritical = props.data.criticalUpdate != undefined && props.data.criticalUpdate.length > 0;
    const handleClose = () => {
        setDownloadsOpen(false);
    }

    const handleCloseMail = () => {
        setMailContent("");
        setSendMailToAllOpen(false);
    }

    const sendMailToAll = () => {
        if (selectedFile?.id) {
            if (mailContent == undefined || mailContent.length < 10) {
                alert("Sie müssen mindestens 10 Zeichen eingeben um eine Mail zu verschicken")
                return;
            }
            if (window.confirm("Möchten Sie wirklich eine Mail an alle schicken?")) {
                context.api.version.versionSendMailToUsers(props.data.major!, props.data.minor!, props.data.patch!, mailContent).then(() => handleClose()).catch(context.apiError);
            }
        }

    }


    const strAny = (str: string | string[] | null | undefined) => {

        if (Array.isArray(str)) {
            return str.filter(e => e.length > 0).length > 0
        }
        return str;
    }

    const typeToString = () => {
        switch (props.data.stage) {
            case EVersionStage.INTERNAL:
                return " -- INTERN --";
            case EVersionStage.ALPHA:
                return " ALPHA";
            case EVersionStage.BETA:
                return " BETA";
            case EVersionStage.RELEASE:
                return "";
            case EVersionStage.APPROVED:
                return "";
        }
        return "--";
    }


    /*


       padding: ,
    paddingLeft: ,

     */

    const listItemStyles: SxProps<Theme> = {
        paddingTop: "2pt", paddingBottom: 0
    }


    const isPrimaryVersion = () => {
        const patch = props.data.patch ?? -1;
        const minor = props.data.minor ?? -1;
        return minor === -1 || patch === -1;

    }

    return (
        <>
            <Item sx={{scrollMargin: 80}}
                  style={{backgroundColor: isCritical ? theme.palette.mode === 'dark' ? "#8F2027" : "#FDD" : undefined}}
                  id={props.data.versionId!}>
                <Box p={{xs: theme.spacing(1), sm: theme.spacing(2)}} pl={{xs: theme.spacing(1), sm: theme.spacing(4)}}>

                    <Typography component="h2" variant="h4" color="primary" textAlign={{xs: "center", sm: "left"}}
                                sx={{paddingBottom: 0, marginBottom: 0}}
                                gutterBottom fontSize={{
                        xs: 25,
                        sm: 40
                    }}>{t('version.item.version', {version: isPrimaryVersion() && "ersion "})}{props.data.major}{(props.data.minor ?? -1) !== -1 && <>.{props.data.minor}{(props.data.patch ?? -1) !== -1 && <>.{props.data.patch}</>}</>}{typeToString()}</Typography>
                    <Typography variant={"caption"}><FontAwesomeIcon icon={faCalendar}
                                                                     fontSize={"inherit"}/> {dayjs(props.data.approved).format("DD.MM.YYYY")}
                    </Typography>
                    {isCritical && <Typography component={"p"} variant={"h6"}
                                               color={"error"}>{t('version.item.criticalUpdate', {update: props.data.criticalUpdate})}</Typography>}
                    <Typography variant={"subtitle1"} fontWeight={"bold"}>{props.data.subtitle}</Typography>
                    {strAny(props.data.header) && props.data.minor !== -1 && props.data.patch !== -1 && <>
                        <MuiMarkdown overrides={context.muiMarkdownOverrides}>{props.data.header!}</MuiMarkdown>
                    </>}

                    {strAny(changes?.highlights) && <>
                        <Typography variant={"h6"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.highlights')}</Typography>
                        <List>
                            {changes?.highlights?.map(elem => <>
                                <ListItem sx={listItemStyles}>
                                    {elem.length > 0 && <>
                                        <FontAwesomeListIcon><FontAwesomeIcon icon={faStars}/></FontAwesomeListIcon>
                                        <FontAwesomeListText>{elem}</FontAwesomeListText>
                                    </>}
                                </ListItem>
                            </>)}
                        </List>
                    </>
                    }
                    {strAny(changes?.features) && <>
                        <Typography variant={"h6"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.features')}</Typography>
                        <List>
                            {changes?.features?.map(elem => <>
                                <ListItem sx={listItemStyles}>
                                    {elem.length > 0 && <>
                                        <FontAwesomeListIcon><FontAwesomeIcon icon={faPlus}/></FontAwesomeListIcon>
                                        <FontAwesomeListText>{elem}</FontAwesomeListText>
                                    </>}
                                </ListItem>
                            </>)}
                        </List>
                    </>
                    }
                    {strAny(changes?.bugfixes) && <>
                        <Typography variant={"h6"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.bugfixes')}</Typography>
                        <List>
                            {changes?.bugfixes?.map(elem => <>
                                <ListItem sx={listItemStyles}>
                                    {elem.length > 0 && <>
                                        <FontAwesomeListIcon><FontAwesomeIcon
                                            icon={faWandMagicSparkles}/></FontAwesomeListIcon>
                                        <FontAwesomeListText>{elem}</FontAwesomeListText>
                                    </>}
                                </ListItem>
                            </>)}
                        </List>
                    </>
                    }
                    {strAny(props.data.description) && <>
                        <Divider/>
                        <Typography variant={"h5"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.information')}</Typography>
                        <MuiMarkdown overrides={context.muiMarkdownOverrides}>{props.data.description!}</MuiMarkdown>
                    </>}
                    {context.isInternal && (props.data as GetVersionData).internalInformation != undefined && strAny((props.data as GetVersionData).internalInformation) && <>
                        <Divider/>
                        <Typography variant={"h5"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.internalInformation')}</Typography>
                        <MuiMarkdown
                            overrides={context.muiMarkdownOverrides}>{(props.data as GetVersionData).internalInformation!}</MuiMarkdown>
                    </>}
                    {(props.data.files?.length ?? 0) > 0 && <>
                        <Divider/>
                        <Accordion sx={{
                            border: 0,
                            boxShadow: 0,
                            paddingLeft: 0,
                            marginLeft: 0,
                            background: "none"
                        }}>
                            <AccordionSummary
                                expandIcon={<FontAwesomeIcon icon={faCaretDown}/>}
                                sx={{
                                    border: 0,
                                    boxShadow: 0,
                                    paddingLeft: 0,
                                    marginLeft: 0
                                }}
                            >
                                <Badge sx={{'& .MuiBadge-badge': {right: -13, top: 16}}}
                                       badgeContent={props.data!.files!.length} color="primary"><Typography
                                    variant={"h5"} color={"secondary"}
                                    fontWeight={"bold"}>{t('version.item.files')}</Typography></Badge>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {props.data.files?.map(file => <ListItem
                                        sx={{paddingRight: {xs: '50px', sm: '"95px"'}}}>
                                        <DownloadFileButton id={file.id!} fileName={file.fileName!}>
                                            <ListItemAvatar sx={{display: {xs: 'none', sm: 'block'}}}>
                                                <Avatar>
                                                    <FontAwesomeIcon icon={faDownload}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                        </DownloadFileButton>
                                        <DownloadFileButton id={file.id!} fileName={file.fileName!}>
                                            <ListItemText
                                                primary={((file.type?.name?.length ?? 0) > 0) ? file.type?.name : `${file.type?.name} - ${file.type?.hostSystemName}`}
                                                secondary={file.fileName}/>
                                        </DownloadFileButton>

                                        <ListItemSecondaryAction sx={{width: {xs: '40px', sm: "90px"}}}>
                                            {(context.userData?.userGroup ?? 4) <= 3 &&

                                                <IconButton onClick={() => {
                                                    setDownloadsOpen(true)
                                                    setSelectedFile(file)
                                                }}><Badge badgeContent={file.downloadedBy?.length}
                                                          color="primary"><FontAwesomeIcon
                                                    icon={faUser}/></Badge></IconButton>

                                            }
                                            {(context.userData?.userGroup ?? 4) <= 1 &&
                                                <IconButton color={"warning"} sx={{display: {xs: 'none', sm: 'inline'}}}
                                                            onClick={() => {
                                                                setSelectedFileDelete(file.id);
                                                            }}><FontAwesomeIcon icon={faTrash}/></IconButton>
                                            }
                                        </ListItemSecondaryAction>

                                    </ListItem>)}

                                </List>
                            </AccordionDetails>
                        </Accordion>


                    </>}
                    {strAny(props.data.footer) && <>
                        <Divider/>
                        <MuiMarkdown overrides={context.muiMarkdownOverrides}>{props.data.footer!}</MuiMarkdown>
                    </>}
                    {(context.userData?.userGroup ?? EUserGroup.CUSTOMER) <= EUserGroup.ADMIN &&
                        <Button fullWidth variant={"outlined"} size={"large"}
                                onClick={() => setSendMailToAllOpen(true)}>{t('version.item.sendEmailToAll')}</Button>
                    }
                </Box>
            </Item>
            <DeleteFileDialg fileDeleted={fileDeleted} id={selectedFileDelete}/>


            <Dialog
                open={sendMailToAllOpen}
                fullWidth
                maxWidth={'md'}
                onClose={handleCloseMail}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('version.item.sendMail')}
                </DialogTitle>
                <DialogContent>
                    <Typography variant={"caption"}
                                fontWeight={"bold"}>{t('version.item.sendMailToAllDescription')}</Typography>
                    <TextField multiline rows={5} fullWidth value={mailContent}
                               onChange={(e) => setMailContent(e.target.value)}/>
                </DialogContent>
                <DialogActions>
                    <Button color={"warning"} onClick={sendMailToAll}><FontAwesomeIcon
                        icon={faTriangleExclamation}/> {t('version.item.sendEmailToAll')}</Button>
                    <Button onClick={handleCloseMail}>{t('version.item.close')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={downloadsOpen}
                fullWidth
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('version.item.downloads')}
                </DialogTitle>
                <DialogContent>
                    <List>
                        {selectedFile?.downloadedBy?.map(data => <>
                            <ListItem>
                                <ListItemText primary={data.name} secondary={data.eMail}/>
                            </ListItem>
                        </>)}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('version.item.close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
