import {Outlet} from "react-router"
import {
    Box,
    Container,
    CssBaseline,
    Grid,
    Toolbar,
    useMediaQuery,
    useTheme
} from "@mui/material";

import React, {useCallback, useEffect, useState} from "react";
import {useApplicationContext} from "ApplicationContext";
import {GetProductsData} from "apiClient/Api";
import SupportDrawer from "Components/SupportDrawer";
import SupportAppBar from "Components/SupportAppBar";
import { Copyright } from "Components/Copyright";

export const BaseLayout = () => {



    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(!isMobile);
    const [initialized, setInitialized] = useState(false)
    const [products, setProducts] = useState<GetProductsData[]>([])
    const context = useApplicationContext();



    const disableDropEvent = (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if(event.dataTransfer)
            event.dataTransfer.dropEffect = 'none';
    };

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile])

    useEffect(() => {

        if(!initialized){
            setInitialized(true)
            context.api.products.productsGetProducts().then(data => {
                if(data && data.data)
                    setProducts(data.data)
            })

        }
    },[initialized, products])



    const toggleDrawer = useCallback(() => {
        console.warn("TOGGLE DRAWER")
        setOpen(o => !o);}, [open])

    return <Box sx={{display: 'flex'}} 
    
    onDrop={disableDropEvent} onDragOver={disableDropEvent}>
        <CssBaseline/>

        <SupportAppBar open={open} handleDrawerToggle={toggleDrawer}/>
        <SupportDrawer handleDrawerToggle={toggleDrawer} open={open} isMobile={isMobile}/>

        <Box
            component="main"
            sx={{
                position: "absolute",
                top:0,
                left: 0,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                zIndex: -6,
                width: '100vw',
                height: '100vh',
            }}
        />
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar/>
            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <Grid container spacing={3}>
                    <Outlet/>
                </Grid>
                <Copyright sx={{pt: 4}}/>
            </Container>
        </Box>
    </Box>
}
