import React, {useCallback, useEffect, useState} from "react";


import {useParams} from "react-router";

import {useDocumentTitle} from "Hooks/useDocumentTitle";
import {GdbMiRecord, GetCoreDumpDTO,} from "../../../apiClient/Api";
import {useApplicationContext} from "../../../ApplicationContext";
import {
    Accordion,
    AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Divider, List, ListItem, ListItemText, Typography
} from "@mui/material";
import {CoreDumpRecordData} from "../../../Components/Admin/CoreDumps/CoreDumpRecordData";
import {ExpandMore} from "@mui/icons-material";
import DataListDisplay from "../../../Components/Admin/CoreDumps/DataListDisplay";





type RouteParams = {
    id: string
}


const CollapsibleField = ({label, value, isError}: { label: string, value: any, isError?: boolean }) => {
    if (value !== undefined && value !== null) {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography sx={{fontWeight: 'bold'}}>{label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={{color: isError ? 'red' : 'inherit', whiteSpace: 'pre-line'}}
                    >
                        {typeof value === 'object' ? <DataListDisplay data={value}/> : value.toString()}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    }
    return null;
};

const DataSection = ({data}: { data: GetCoreDumpDTO }) => {
    const renderListItem = (label: string, value: any, isError?: boolean) => {
        if (value !== undefined && value !== null) {
            return (
                <>
                    <ListItem>
                        <ListItemText
                            primary={label}
                            secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{color: isError ? 'red' : 'inherit', whiteSpace: 'pre-line'}}
                                >
                                    {typeof value === 'object' ? JSON.stringify(value) : value.toString()}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <Divider component="li"/>
                </>
            );
        }
        return null;
    };

    return (
        <Box>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography variant="h6">Core Dump Parameter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        {renderListItem('ID', data.id)}
                        {renderListItem('Build ID', data.buildId)}
                        {renderListItem('Build Pipeline', data.buildPipeline)}
                        {renderListItem('Debug', data.debug ? 'Yes' : 'No')}
                        {renderListItem('OS Name', data.osName)}
                        {renderListItem('OS Release', data.osRelease)}
                        {renderListItem('Platform', data.platform)}
                        {renderListItem('Support Code', data.supportCode)}
                        {renderListItem('System Name', data.systemName)}
                        {renderListItem('Version', data.version)}
                        {renderListItem('Successfully Finished', data.sucessfullyFinished)}
                        {renderListItem('Created', data.created)}
                        {renderListItem('Deleted From Storage', data.isDeletedFromStorage ? 'Yes' : 'No')}
                    </List>
                    {data.serviceComment &&
                        <CollapsibleField label="Service Commen" value={data.serviceComment} isError/>}
                    {data.error && <CollapsibleField label="Error" value={data.error} isError/>}
                    {data.metadata && <CollapsibleField label="Metadata" value={data.metadata}/>}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export const CoreDumpData = () => {
    const params = useParams<RouteParams>()
    const [data, setData] = useState<GetCoreDumpDTO | undefined>(undefined)
    useDocumentTitle(`Log Report - ${params.id}`)

    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0)


    const context = useApplicationContext();

    var controller = context.api.gdbService;


    const reloadCallback = useCallback(async () => {
        await fetchData()
    }, [data])

    async function fetchData() {

        if (!params.id)
            return;

        setIsFetching(true)

        var value = await controller.gdbServiceGetResultById(params.id)
        if (value && value.data)
        {
            // Refetch because error and sucessfullyFinished is both null

            setData(value.data)

        }
        setIsFetching(false)

    }

    useEffect(() => {
        var timeout: NodeJS.Timeout | undefined = undefined;
        if(data != null){
            if(data.sucessfullyFinished == null && data.error == null){
                timeout = setTimeout(fetchData, 1500);

            }
        }


        return () => {
            if(timeout != undefined)
                clearTimeout(timeout)
        }
    }, [data]);

    useEffect(() => {

        fetchData();
    }, [params.id])

    return <>
        <div style={{width: "100%"}}>
            <h1>Core Dump von: {data?.systemName}</h1>
            <div>
                {data && <DataSection data={data}/>}
            </div>
            <div>
                {data?.sucessfullyFinished != null && params.id && <CoreDumpRecordData id={params.id}/>}
            </div>
            <div>
                {!(data?.sucessfullyFinished || data?.error) && <>CoreDump wird verarbeitet...</> }
            </div>
        </div>
    </>
}


