import React, {useCallback, useEffect, useState} from "react";


import {useParams} from "react-router";

import {useDocumentTitle} from "Hooks/useDocumentTitle";
import {GdbMiRecord, } from "../../../apiClient/Api";
import {useApplicationContext} from "../../../ApplicationContext";
import {Button, ButtonGroup} from "@mui/material";
import ConsoleStreamOutput, {ConsoleStreamOutputData} from "./ConsoleStreamOutput";

type GbiMiRecordType = 'NotifyAsyncRecord' | 'StatusAsyncRecord' |'ExecAsyncRecord' | 'ConsoleStreamOutput' | 'LogStreamOutput' |'TargetStreamOutput'

type SelectedTypes = {
    [key in GbiMiRecordType]: boolean;
};

type RouteParams = {
    id: string
}


export const CoreDumpRecordData = (params: RouteParams) => {
    const [data, setData] = useState<GdbMiRecord[]>([])
    useDocumentTitle(`Log Report - ${params.id}`)

    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0)


    const context = useApplicationContext();

    var controller = context.api.gdbService;


    const reloadCallback = useCallback(async () => {
        await fetchData()
    }, [data])

    async function fetchData() {

        if (!params.id)
            return;

        setIsFetching(true)

        var value = await controller.gdbServiceGetResultDataById(params.id)
        if (value && value.data)
            setData(value.data)
        setIsFetching(false)

    }

    useEffect(() => {

        fetchData();
    }, [params.id])

    interface NotifyAsyncRecord {
        recordType?: string;
        fields?: { [key: string]: string };
        typeDiscriminator: string;
    }

    const printNotifyAsyncRecord = (record: NotifyAsyncRecord) =>{
        if(record.recordType == null || record.fields == null)
            return <div style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px',  color: "red"}}>Fehlerhafter Datensatz NotifyAsyncRecord</div>

        var data = Object.entries(record.fields);
        return (
            <div style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', backgroundColor: "#007bff", color: "white" }}>
                <p style={{fontWeight: "bold"}}>Notification</p>
                <h3>Record Type: {record.recordType}</h3>
                <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '10px' }}>
                    <>
                        {data.map((data, index) =>
                            <>
                                <div key={index}><strong>{data[0]}:</strong> {data[1]}</div>
                            </>
                        )}
                    </>
                </div>
            </div>
        );
    }

    interface LogStreamRecord {
        warning?: string;
    }

    const printLogStreamRecord = (record: LogStreamRecord) =>{
        if(record.warning== null)
            return <div style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', color: "red" }}>Fehlerhafter Datensatz LogStreamRecord</div>

        return (
            <div style={{ marginBottom: '20px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', backgroundColor: "#f8f9fa", color: "black" }}>
                <p style={{fontWeight: "bold"}}>Log</p>
                <h3>{record.warning}</h3>
            </div>
        );
    }




    interface ErrorBoxProps {
        message: string;
    }

    const ErrorBox: React.FC<ErrorBoxProps> = ({ message }) => (
        <div style={{ border: '1px solid red', padding: '10px', borderRadius: '5px', color: 'red', marginBottom: '10px' }}>
            <p>Error: {message}</p>
        </div>
    );

    const getLevelColors = (level?: number) => {
        if (!level || level > 10) {
            // Slightly more distinct default color
            return { backgroundColor: '#e0e0e0', color: '#333333' }; // Lighter grey
        }

        let backgroundColor;
        let color = '#ffffff'; // Default to white for darker backgrounds

        switch (level) {
            case 1:
                backgroundColor = '#b30000'; // Dark red for highest severity
                break;
            case 2:
                backgroundColor = '#e60000'; // Bright red
                break;
            case 3:
                backgroundColor = '#ff6600'; // Bright orange
                break;
            case 4:
                backgroundColor = '#ff9933'; // Softer orange
                break;
            case 5:
                backgroundColor = '#ffcc66'; // Light orange
                color = '#333333'; // Darker text for better readability
                break;
            case 6:
                backgroundColor = '#ffff99'; // Light yellow
                color = '#333333'; // Darker text for better readability
                break;
            case 7:
                backgroundColor = '#ccffcc'; // Very light green
                color = '#333333'; // Darker text for better readability
                break;
            case 8:
                backgroundColor = '#ccffff'; // Light cyan
                color = '#333333'; // Darker text for better readability
                break;
            case 9:
                backgroundColor = '#cce6ff'; // Very light blue
                color = '#333333'; // Darker text for better readability
                break;
            case 10:
                backgroundColor = '#f2f2f2'; // Almost white
                color = '#333333'; // Darker text for better readability
                break;
        }

        return { backgroundColor, color };
    };





    const printType = (row: GdbMiRecord) => {


        switch (row.typeDiscriminator){
            case "NotifyAsyncRecord":
                return printNotifyAsyncRecord(row as NotifyAsyncRecord);
            case "ConsoleStreamOutput":
                return <ConsoleStreamOutput row={row as ConsoleStreamOutputData} />
            case "LogStreamOutput":
                return printLogStreamRecord(row as LogStreamRecord);
        }

        return <>
            {JSON.stringify(row)}
        </>

    }


    // Toggle function for buttons
    const [selectedTypes, setSelectedTypes] = useState<SelectedTypes>({
        NotifyAsyncRecord: false,
        ConsoleStreamOutput: false,
        LogStreamOutput: false,
        StatusAsyncRecord: false,
        ExecAsyncRecord: false,
        TargetStreamOutput: false
    });

    const isNoneSelected = () => {
        return Object.values(selectedTypes).every(value => !value);
    };

    const toggleType = (type: keyof SelectedTypes) => {
        setSelectedTypes(prevTypes => ({
            ...prevTypes,
            [type]: !prevTypes[type],
        }));
    };


    useEffect(() => {
        fetchData();
    }, [params.id])




    return (
        <div>
            <h2>Ausgewerteter Record {params.id}</h2>
            <p>Info: Bitte die gewünschten Elemente aktivieren</p>
            <ButtonGroup variant="outlined" >
                <Button
                    onClick={() => toggleType('ConsoleStreamOutput')}
                    color={selectedTypes['ConsoleStreamOutput'] ? "success" : "error"}
                >
                    Konsole
                </Button>
                <Button
                    onClick={() => toggleType('NotifyAsyncRecord')}

                    color={selectedTypes['NotifyAsyncRecord'] ? "success" : "error"}
                >
                    Benachrichtigungen
                </Button>
                <Button
                    onClick={() => toggleType('LogStreamOutput')}

                    color={selectedTypes['LogStreamOutput'] ? "success" : "error"}
                >
                    Log
                </Button>
                <Button
                    onClick={() => toggleType('StatusAsyncRecord')}

                    color={selectedTypes['StatusAsyncRecord'] ? "success" : "error"}
                >
                    Status
                </Button>
                <Button
                    onClick={() => toggleType('ExecAsyncRecord')}

                    color={selectedTypes['ExecAsyncRecord'] ? "success" : "error"}
                >
                    Ausführung
                </Button>
                <Button
                    onClick={() => toggleType('TargetStreamOutput')}

                    color={selectedTypes['TargetStreamOutput'] ? "success" : "error"}
                >
                    Zielstrom
                </Button>
            </ButtonGroup>


            <div>
                {data.filter(row => row.typeDiscriminator && selectedTypes[row.typeDiscriminator as GbiMiRecordType]).map(row => printType(row))}
                {isNoneSelected() && "Es wurden keine Typen ausgewählt. Bitte wählen sie Einen Typ um diesen anzuzeigen."}
            </div>
        </div>
    );
}


