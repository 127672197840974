import React, { useState, useEffect } from 'react';
import { Button, Container, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from "@mui/material";
import { useApplicationContext } from "ApplicationContext";
import { useDocumentTitle } from "Hooks/useDocumentTitle";
import { FileTypeClass, GetFileTypeData } from 'apiClient/Api';
import IconPlaceholder from 'Pages/Admin/IconPlaceholder';
import { Link } from 'react-router';

/**
 * Applications component to display a list of applications.
 * @returns {JSX.Element} The rendered component.
 */
export const Applications = (): JSX.Element => {
    useDocumentTitle("base.pages.applications");
    const [fileTypeData, setFileTypeData] = useState<GetFileTypeData[]>();
    const context = useApplicationContext();
    const controller = context.api.fileType;


    useEffect(() => {
        if( context.signInState){
            controller.fileTypeGetFileTypes({variant:FileTypeClass.APPLICATION}).then(data => {
                if (data.data) {
                    setFileTypeData(data.data);
                } else {
                    setFileTypeData([]);
                }
            }).catch(context.apiError);    
        }
        else{
            controller.fileTypeGetFileTypesPublic({variant:FileTypeClass.APPLICATION}).then(data => {
                if (data.data) {
                    setFileTypeData(data.data);
                } else {
                    setFileTypeData([]);
                }
            }).catch(context.apiError);   
        }
    }, []);

    return (
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography variant="h4" component={"h1"}>Anwendungen</Typography>
                <List>
                    {fileTypeData?.map((fileType) => (
                        <ListItemButton component={Link} to={`${fileType.id}`} key={fileType.id}>
                            <ListItemAvatar>
                                <IconPlaceholder size={40} iconId={fileType.iconId} />
                            </ListItemAvatar>
                            <ListItemText 
                                primary={fileType.name}
                                secondary={fileType.hostSystemName} 
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Paper>
        </Container>
    );
};