import {Box, Button, Typography, useTheme} from "@mui/material";
import {Link} from "react-router";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSignalStreamSlash, faUserRobotXmarks
} from '@fortawesome/pro-duotone-svg-icons';
export const NotFound = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh',
                textAlign: 'center',
                bgcolor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                width: '150%', // Make the width 1.5 times of the parent
                mx: 'auto', // Center it on the page
                p: 2, // Some padding
            }}
        >
            <Typography fontSize={60} gutterBottom fontWeight={"bold"}>
                <FontAwesomeIcon icon={faSignalStreamSlash} style={{marginRight: 8}}/>
                <FontAwesomeIcon icon={faUserRobotXmarks} style={{marginLeft: 8}}/>
            </Typography>
            <Typography component="h1" variant="h2" gutterBottom color="secondary">
                404
            </Typography>
            <Typography variant="h5" gutterBottom>
                {t('notFound.pageNotExist')}
            </Typography>
            <Typography variant="subtitle1" color="text.light">
                {t('notFound.supportingText')}
            </Typography>
            <Box mt={5}>
                <Button variant="outlined" color="inherit" component={Link} to="/">
                    {t('notFound.homeButton')}
                </Button>
            </Box>
        </Box>
    );
};
