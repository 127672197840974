import {
    DataGrid,
    GridActionsCellItem, GridEventListener, GridRowId, GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    GridRowsProp, GridToolbarContainer, MuiEvent
} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Input, List, ListItem,
    Modal, TableContainer, TextField,
    Typography
} from "@mui/material";
import {Edit, OpenInFull, Workspaces} from "@mui/icons-material";
import {useDocumentTitle} from "../../Hooks/useDocumentTitle";
import {useApplicationContext} from "../../ApplicationContext";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {AddHostSystemData, GetHostSystemData} from "../../apiClient/Api";
import Paper from "@mui/material/Paper";


interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp<GetHostSystemData>) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps) {
    const {setRows, setRowModesModel} = props;

    const handleClick = () => {
        const id = "NEW";
        setRows((oldRows) => [...oldRows, {
            id,
            name: 'HostSystem',
            minVersion: 0,
            maxVersion: null,
            description: "",
            isNew: true
        }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                Host System hinzufügen
            </Button>
        </GridToolbarContainer>
    );
}


export const AdminHostSystems = () => {
    useDocumentTitle("base.pages.systems")
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [selectedRow, setSelecedRow] = useState<GridRowId | undefined>(undefined);
    const [rows, setRows] = useState<GridRowsProp>([])
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const context = useApplicationContext();
    const controller = context.api.fileType;
    const [deleteEntryValue,setDeleteEntryValue] = useState("")

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        var row = params.row;
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setSelecedRow(id);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (newRow.isNew) {
            try {

                var rowData: AddHostSystemData = {
                    name: newRow.name,
                    description: newRow.description,
                    minVersion: newRow.minVersion.toString(),
                    maxVersion: newRow.maxVersion != null ? newRow.maxVersion.toString() : null,
                    hostSystemCustomId: newRow.hostSystemCustomId,
                }

                var row = await controller.fileTypeAddHostSystem(rowData);
                if (row.data != undefined) {
                    setRows([...rows.filter((row) => row.id !== newRow.id), row.data]);
                }
                return row.data;
            } catch (e) {
                context.apiError(e)
                return null;
            }
        } else {
            try {

                var rowData: AddHostSystemData = {
                    name: newRow.name,
                    description: newRow.description,
                    minVersion: newRow.minVersion.toString(),
                    maxVersion: newRow.maxVersion != null ? newRow.maxVersion.toString() : null,
                    hostSystemCustomId: newRow.hostSystemCustomId,
                }
                var row = await controller.fileTypeUpdateHostSystem(newRow.id, rowData);
                if (row.data != undefined) {
                    setRows([...rows.filter((row) => row.id !== newRow.id), row.data]);
                }
                return row.data;
            } catch (e) {
                context.apiError(e)
                return null;
            }
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };


    async function fetchData() {
        setIsFetching(true)
        var value = await controller.fileTypeGetHostSystems()
        setRows(value.data)
        setIsFetching(false)
    }

    useEffect(() => {
        if (!isData) {
            setIsData(true);
            fetchData();
        }

    }, [rows, isData, isFetching])

    const handleDeleteDialogClose = (save: boolean) => () => {


        if (save && selectedRow != undefined) {
            var row = selectedRow.toString();
            var value =  rows.find(r => r.id == selectedRow)?.name
            if(value == undefined)
            {
                setSelecedRow(undefined)
            }
            else{

                if(deleteEntryValue != value)
                    return;

                controller.fileTypeDeleteHostSystem(row).then(e => {
                    setRows(srows => srows.filter((row) => row.id !== row));
                    setSelecedRow(undefined);
                    fetchData();
                    setDeleteEntryValue("")
                }).catch(context.apiError)

            }
        } else {
            setSelecedRow(undefined)
            setDeleteEntryValue("")
        }
    }

    return <>
        <Dialog
            open={selectedRow != undefined}
            onClose={handleDeleteDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Möchten Sie den Eintrag wirklich löschen?
                </DialogContentText>
                {selectedRow != undefined && <>
                    <DialogContentText fontWeight={"bold"} id="alert-dialog-description">
                        Zum löschen {rows.find(r => r.id == selectedRow)?.name} in das Textfeld eingeben
                    </DialogContentText></>}
                <TextField  value={deleteEntryValue} onChange={target => setDeleteEntryValue(target.target.value)} fullWidth margin={"dense"}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteDialogClose(false)} color={"error"}>Nein</Button>
                <Button onClick={handleDeleteDialogClose(true)} color={"success"} autoFocus>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
        <TableContainer component={Paper} style={{margin: 4}}>
            <DataGrid
                editMode={"row"}
                getRowId={(row) => row.id!}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: {setRows, setRowModesModel},
                }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => console.error(error)}
                columns={[
                    {field: "name", headerName: "Name", editable: true, type: "string", flex: 1, maxWidth: 200},
                    {field: "minVersion", headerName: "Min. Version", editable: true, type: "number", width: 100},
                    {field: "maxVersion", headerName: "Max. Version", editable: true, type: "number", width: 100},
                    {field: "description", headerName: "Beschreibung", flex: 1, editable: true, type: "string"},
                    {field: "hostSystemCustomId", headerName: "Referenz", flex: 1, editable: true, type: "string"},
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 100,
                        cellClassName: 'actions',
                        getActions: ({id}) => {
                            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                            if (isInEditMode) {
                                return [
                                    <GridActionsCellItem
                                        icon={<SaveIcon/>}
                                        label="Save"
                                        onClick={handleSaveClick(id)}
                                    />,
                                    <GridActionsCellItem
                                        icon={<CancelIcon/>}
                                        label="Cancel"
                                        className="textPrimary"
                                        onClick={handleCancelClick(id)}
                                        color="inherit"
                                    />,
                                ];
                            }

                            return [
                                <GridActionsCellItem
                                    icon={<EditIcon/>}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                                />,
                                <GridActionsCellItem
                                    icon={<DeleteIcon/>}
                                    label="Delete"
                                    onClick={handleDeleteClick(id)}
                                    color="inherit"
                                />,
                            ];
                        },
                    }
                ]}
                rows={rows}
                loading={isFetching}
                autoHeight={true}/>
        </TableContainer>
    </>
}
