import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';

import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import {useCallback, useState} from "react";
import {Link} from 'react-router';
import {Copyright} from "Components/Copyright";
import ReCAPTCHA from "react-google-recaptcha";

import {faLock, faPhone, faEnvelope, faCircleInfo, faGlobe} from '@fortawesome/pro-duotone-svg-icons'
import {useApplicationContext} from "../../ApplicationContext";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LanguageButton from "../../Components/LanguageButton";


export type FormDataType = {
    customerName: string;
    firstName: string;
    lastName: string;
    email: string;
    supportContactId: string;
    furtherInformation: string;
    allowExtraMail: string;
    allowSaveData: string;
    sex: string;
    recaptcha: string;
}

const iniitalFormData: FormDataType = {
    customerName: '',
    firstName: '',
    lastName: '',
    email: '',
    supportContactId: '',
    furtherInformation: '',
    allowExtraMail: '',
    allowSaveData: '',
    sex: '',
    recaptcha: ''
}

const supportHotlineNumber = '+49 201 - 82 13 45 112';
const salesInquiriesEmail = 'vertrieb@reichert-gmbh.com';
const RECAPTCHA_SITE_KEY = "6Len7BgnAAAAAHfUokbSZmD6zVGRWhD9ZSc0qVlV"

const WelcomeMessage = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook
    const theme = useTheme();

    return (
        <div>
            <Typography variant="h4" gutterBottom color="primary">
                {t('signup.welcomeMessage.header')}
            </Typography>

            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.registrationIntro')}
            </Typography>

            <Typography variant="h5" gutterBottom color="secondary">
                {t('signup.welcomeMessage.note')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.exclusiveAccess')}
            </Typography>

            <Typography variant="h5" gutterBottom color="secondary">
                {t('signup.welcomeMessage.benefits')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.activationBenefits')}
            </Typography>
            <List dense>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t('signup.welcomeMessage.softwareUpdates')}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t('signup.welcomeMessage.createSupportTickets')}
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t('signup.welcomeMessage.receiveUpdates')}
                    </ListItemText>
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom color="secondary">
                {t('signup.welcomeMessage.contacts')}
            </Typography>
            <List dense>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faPhone}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>{t('signup.welcomeMessage.supportHotline')}: {supportHotlineNumber}</ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faGlobe}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t('signup.welcomeMessage.useSupportForm')}
                        <MuiLink component={Link} to="/support">
                            {t('signup.welcomeMessage.quickSupport')}
                        </MuiLink>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{ color: theme.palette.primary.main }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        {t('signup.welcomeMessage.salesInquiries')}: {salesInquiriesEmail}
                    </ListItemText>
                </ListItem>
            </List>

            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.thankYou')}
            </Typography>

            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.regards')}
            </Typography>
            <Typography variant="body1" paragraph>
                {t('signup.welcomeMessage.reichertTeam')}
            </Typography>
        </div>
    );
};

export const SignUp = () => {
    const { t } = useTranslation();
    const possibleAnreden = [
        {key: 'male', value: t('signup.signupform.male')},
        {key: 'female', value: t('signup.signupform.female')},
        {key: 'non-binary', value: t('signup.signupform.non-binary')}
    ];
    const [formValidationErrors, setFormValidationErrors] = useState(iniitalFormData);
    const [signUpComplete, setSignUpComplete] = useState(false);
    const recaptchaRef = React.useRef<ReCAPTCHA>(null);
    const context = useApplicationContext();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let currentFormValidationData = {...iniitalFormData};
        const data = new FormData(event.currentTarget);
        let withError = false;
        var recaptchaData = recaptchaRef?.current?.getValue();
        if(recaptchaData == null || recaptchaData.length == 0) {
            withError = true;
            currentFormValidationData.recaptcha = t('signup.signupform.recaptchaError');
        }

        let parsedData = {
            customerName: data.get('customer')?.toString(),
            firstName: data.get('firstName')?.toString(),
            lastName: data.get('lastName')?.toString(),
            sex: data.get('sex')?.toString() ?? '',
            email: data.get('email')?.toString(),
            supportContractId: data.get('supportContractId')?.toString(),
            furtherInformation: data.get('furtherInformation')?.toString(),
            allowExtraMail: data.get('allowExtraEmails')?.toString() === "allowExtraEMails",
            allowSaveData: data.get('allowSaveData')?.toString() === "allowSaveData"
        };

        if (!parsedData.allowSaveData) {
            withError = true;
            currentFormValidationData.allowSaveData = t('signup.signupform.dataSavingError');
        }
        if (!parsedData.customerName || parsedData.customerName!.length < 3) {
            withError = true;
            currentFormValidationData.customerName = t('signup.signupform.customerNameError');
        }
        if (!parsedData.sex || parsedData.sex.length === 0) {
            withError = true;
            currentFormValidationData.sex = t('signup.signupform.sexError');
        }
        if (!parsedData.firstName || parsedData.firstName!.length < 4) {
            withError = true;
            currentFormValidationData.firstName = t('signup.signupform.firstNameError');
        }
        if (!parsedData.lastName || parsedData.lastName!.length < 4) {
            withError = true;
            currentFormValidationData.lastName = t('signup.signupform.lastNameError');
        }
        if (!parsedData.email || parsedData.email!.length < 4) {
            withError = true;
            currentFormValidationData.email = t('signup.signupform.emailError');
        }
        setFormValidationErrors(currentFormValidationData)

        if (withError) {
            return;
        }

        context.api.user.userSignUp({
            customer: parsedData.customerName!,
            email: parsedData.email!,
            firstName: parsedData.firstName!,
            lastName: parsedData.lastName!,
            supportContractId: parsedData.supportContractId,
            furtherInformation: parsedData.furtherInformation,
            allowExtraMail: parsedData.allowExtraMail,
            allowSaveData: parsedData.allowSaveData,
            sex: parsedData.sex,
            reCaptchaToken: recaptchaData!
        }).then(data => setSignUpComplete(data.data)).catch(context.apiError);
    };

    function onChange(value : any) {
        // console.log("Captcha value:", value);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline/>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <FontAwesomeIcon icon={faLock}/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('signup.signupform.register')}
                </Typography>

                {signUpComplete ? <>
                    <Box sx={{mt: 3}}>
                        <WelcomeMessage/>
                        <Button fullWidth variant={"outlined"} sx={{marginTop: 2, marginBottom: 2}} component={Link}
                                to={"/"}>
                            {t('signup.signupform.backToHomePage')}
                        </Button>
                    </Box>

                </> : <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 3}}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                error={formValidationErrors.customerName.length > 0}
                                helperText={formValidationErrors.customerName}
                                required
                                fullWidth
                                id="customer"
                                label={t('signup.signupform.customerName')}
                                name="customer"
                                autoComplete="customer"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="sex">{t('signup.signupform.sex')}</InputLabel>
                                <Select name={"sex"} id={"sex"} fullWidth required
                                        error={formValidationErrors.sex.length > 0}>
                                    {possibleAnreden.map((anrede, index) => (
                                        <MenuItem key={index} value={anrede.key}>{anrede.value}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={true}>{formValidationErrors.sex}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={formValidationErrors.firstName.length > 0}
                                helperText={formValidationErrors.firstName}
                                required
                                fullWidth
                                id="firstName"
                                label={t('signup.signupform.firstName')}
                                name="firstName"
                                autoComplete="firstName"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={formValidationErrors.lastName.length > 0}
                                helperText={formValidationErrors.lastName}
                                required
                                fullWidth
                                id="lastName"
                                label={t('signup.signupform.lastName')}
                                name="lastName"
                                autoComplete="lastName"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={formValidationErrors.email.length > 0}
                                helperText={formValidationErrors.email}
                                required
                                fullWidth
                                id="email"
                                label={t('signup.signupform.email')}
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                error={formValidationErrors.supportContactId.length > 0}
                                helperText={formValidationErrors.supportContactId}
                                fullWidth
                                id="supportContractId"
                                label={t('signup.signupform.supportContractId')}
                                name="supportContractId"
                                autoComplete="supportContractId"
                            />
                        </Grid>





                        <Grid item xs={12}>
                            <TextField
                                error={formValidationErrors.furtherInformation.length > 0}
                                helperText={formValidationErrors.furtherInformation}
                                fullWidth
                                id="furtherInformation"
                                label={t('signup.signupform.furtherInformation')}
                                name="furtherInformation"
                                autoComplete="furtherInformation"
                                multiline
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox color="primary" name="allowExtraEmails"/>}
                                label={t('signup.signupform.allowExtraEmails')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        name="allowSaveData"
                                        value="allowSaveData"
                                        required
                                    />
                                }
                                label={t('signup.signupform.allowSaveData')}
                            />
                            {formValidationErrors.allowSaveData.length > 0 &&
                                <FormHelperText error>{formValidationErrors.allowSaveData}</FormHelperText>}
                        </Grid>


                        <Grid item xs={12}>
                            <ReCAPTCHA ref={recaptchaRef} size="normal" sitekey={RECAPTCHA_SITE_KEY} onChange={onChange}/>
                            {formValidationErrors.recaptcha.length > 0 &&
                                <FormHelperText error>{formValidationErrors.recaptcha}</FormHelperText>}
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {t('signup.signupform.register')}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button fullWidth  sx={{marginTop: 1, marginBottom: 1}} component={Link}
                                    to={"/"}>
                                {t('signup.signupform.alreadyHaveAccount')}
                            </Button>
                        </Grid>

                    </Grid>
                </Box>}
            </Box>
            <LanguageButton floating/>
            <Copyright/>
        </Container>
    );
}
