import React from 'react';
import { useTheme } from '@mui/material/styles';

const ScreenshotPlaceholder = () => {
  const theme = useTheme();
  const foregroundColor = theme.palette.text.primary;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 150" width="200" height="150">
      {/* Monitor */}
      <rect x="10" y="10" width="180" height="100" fill="none" stroke={foregroundColor} strokeWidth="2" rx="10" ry="10" />

      {/* Diagonale Linien */}
      <line x1="30" y1="30" x2="170" y2="80" stroke={foregroundColor} strokeWidth="1" />
      <line x1="170" y1="30" x2="30" y2="80" stroke={foregroundColor} strokeWidth="1" />

      {/* Regel der Drittel Linien */}
      <line x1="70" y1="10" x2="70" y2="110" stroke={foregroundColor} strokeWidth="1" />
      <line x1="130" y1="10" x2="130" y2="110" stroke={foregroundColor} strokeWidth="1" />
      <line x1="10" y1="43.33" x2="190" y2="43.33" stroke={foregroundColor} strokeWidth="1" />
      <line x1="10" y1="76.67" x2="190" y2="76.67" stroke={foregroundColor} strokeWidth="1" />

      {/* Standfuß */}
      <rect x="85" y="110" width="30" height="10" fill="none" stroke={foregroundColor} strokeWidth="2" />
      <rect x="75" y="120" width="50" height="10" fill="none" stroke={foregroundColor} strokeWidth="2" />
    </svg>
  );
};

export default ScreenshotPlaceholder;
