import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, ButtonGroup } from '@mui/material';
import { useApplicationContext } from 'ApplicationContext';
import { DeviceQueryModel, GetSupportCodeData, DeviceStatusEnum, SetDeviceStatusModel } from 'apiClient/Api';
import { useParams } from 'react-router';
import { useDocumentTitle } from 'Hooks/useDocumentTitle';

/**
 * DeviceManagement component
 * @returns {JSX.Element} The DeviceManagement page
 */
const DeviceManagement: React.FC = (): JSX.Element => {

  useDocumentTitle("base.pages.deviceManagement")
  const context = useApplicationContext();
  const controller = context.api.device;
  const supportCodeController = context.api.supportCode;

  const { t } = useTranslation();
  const [devices, setDevices] = React.useState<DeviceQueryModel[]>([]);
  const [supportCode, setSupportCode] = React.useState<GetSupportCodeData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { supportId } = useParams<{ supportId?: string }>();

  /**
   * Updates the data by fetching devices and support code if available
   */
  const updateData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const deviceData = await controller.deviceGetDevices({ supportCodeId: supportId });
      if (deviceData && deviceData.data) {
        setDevices(deviceData.data);
      }

      if (supportId) {
        const supportCodeData = await supportCodeController.supportCodeGetSupportCode(supportId);
        if (supportCodeData && supportCodeData.data) {
          setSupportCode(supportCodeData.data);
        } else {
          setError(t('error.supportCodeNotFound'));
        }
      } else {
        setSupportCode(null);
      }
    } catch (err) {
      setError(t('error.fetchFailed'));
    } finally {
      setLoading(false);
    }
  }, [supportId, t, controller, supportCodeController]);

  React.useEffect(() => {
    updateData();
  }, [supportId, updateData]);

  /**
   * Handles the status change of a device
   * @param {string} deviceId - The ID of the device
   * @param {DeviceStatusEnum} newStatus - The new status to set
   */
  const handleStatusChange = async (deviceId: string, newStatus: DeviceStatusEnum) => {
    try {
      const data: SetDeviceStatusModel = { deviceId, newStatus };
      await controller.deviceSetDeviceStatus(data);
      updateData();
    } catch (err) {
      setError(t('error.fetchFailed'));
    }
  };

  /**
   * Renders action buttons based on device status
   * @param {DeviceStatusEnum} status - The status of the device
   * @param {string} deviceId - The ID of the device
   * @returns {JSX.Element} The action buttons
   */
  const renderActionButtons = (status?: DeviceStatusEnum, deviceId?: string): JSX.Element => {
    switch (status) {
      case DeviceStatusEnum.Pending:
        return (
          <ButtonGroup>
            <Button variant="contained" color="primary" onClick={() => handleStatusChange(deviceId!, DeviceStatusEnum.Active)}>
              {t('deviceManagement.actions.activate')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleStatusChange(deviceId!, DeviceStatusEnum.Inactive)}>
              {t('deviceManagement.actions.revoke')}
            </Button>
          </ButtonGroup>
        );
      case DeviceStatusEnum.Active:
        return (
          <Button variant="contained" color="secondary" onClick={() => handleStatusChange(deviceId!, DeviceStatusEnum.Inactive)}>
            {t('deviceManagement.actions.revoke')}
          </Button>
        );
      case DeviceStatusEnum.Inactive:
        return (

          <ButtonGroup>
            <Button variant="contained" color="primary" onClick={() => handleStatusChange(deviceId!, DeviceStatusEnum.Active)}>
              {t('deviceManagement.actions.activate')}
            </Button>
            <Button variant="contained" color="error" onClick={() => handleStatusChange(deviceId!, DeviceStatusEnum.Deleted)}>
            {t('deviceManagement.actions.delete')}
            </Button>
          </ButtonGroup>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography variant="h4" component={"h1"} align="center">{t('deviceManagement.title')}</Typography>
        {loading ? <Typography>{t('loading')}</Typography> : (
          <>
            {supportCode && (
              <div>
                <Typography variant="h6">{t('supportCode.id')}: {supportCode.id}</Typography>
                <Typography variant="h6">{t('supportCode.name')}: {supportCode.name}</Typography>
              </div>
            )}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('deviceManagement.columns.id')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.deviceId')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.hostSystemName')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.osVersion')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.supportCode')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.deviceStatus')}</TableCell>
                    <TableCell>{t('deviceManagement.columns.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices.map((device) => (
                    <TableRow key={device.id}>
                      <TableCell>{device.id}</TableCell>
                      <TableCell>{device.deviceId}</TableCell>
                      <TableCell>{device.hostSystemName}</TableCell>
                      <TableCell>{device.osVersion}</TableCell>
                      <TableCell>{device.supportCode}</TableCell>
                      <TableCell>{t(`deviceManagement.status.${device.deviceStatus}`)}</TableCell>
                      <TableCell>{renderActionButtons(device.deviceStatus, device.deviceId)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
        {/* Add your page content here */}
      </Paper>
    </Container>
  );
};

export default DeviceManagement;
