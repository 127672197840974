import React, { useCallback, useEffect, useState } from 'react';
import { Paper, Typography, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { useApplicationContext } from 'ApplicationContext';
import { ContentCopy } from '@mui/icons-material';
import { FileTypeLicenseDto, FileTypeVariantsDto } from 'apiClient/Api';
import {QRCodeSVG} from 'qrcode.react'; // Import QRCode component

/**
 * LicenseGenerator component
 * 
 * This component renders a form for generating licenses with three text fields.
 * If a code is entered in the first text field, it is automatically copied to the other two fields.
 * The input is formatted with separators (-) and automatically jumps to the next block.
 */
export const LicenseGenerator: React.FC = () => {
    const { t } = useTranslation();
    const [licenseCode, setLicenseCode] = useState<string>('');
    const [modus, setModus] = useState<string | null>(null);
    const [key, setKey] = useState<string | null>(null);
    const [licenseGeneratorVersions, setLicenseGeneratorVersions] =useState<FileTypeLicenseDto[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined);
    const [variants, setVariants] = useState<FileTypeVariantsDto[]>([]);
    const context = useApplicationContext();
    

    useEffect(() => {
        context.api.service.serviceGetLicenseVersions().then((data) => {
            setLicenseGeneratorVersions(data.data);
        }).catch((error) => {
            console.error(error);
        });
    },[]);

    useEffect(() => {
        setModus(null);
        if (selectedProduct) {
            setVariants(licenseGeneratorVersions.find((product) => product.id === selectedProduct)?.variants || []);
     
        } else {
            setVariants([]);
        }
    }, [selectedProduct, licenseGeneratorVersions]);

    /**
     * Fetches license data based on the provided license code and modus.
     * 
     * @param licenseCode - The license code
     */
    const getLicenseData = useCallback(async (licenseCode: string) => {
        context.api.service.serviceGenerateLicenceKey(
            {
                hostId: licenseCode,
                product: selectedProduct,
                variant: modus            
            }
        ).then((data) => {
            setKey(data.data);
        }).catch((error) => {
            setKey(null);
        });
    }, [selectedProduct, modus]);

    /**
     * Initiates a download of the license key as a file.
     * 
     * @param key - The license key
     */
    const downloadKeyAsFile = (key: string) => {
        const element = document.createElement("a");
        const file = new Blob([key], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "license-host.key";
        document.body.appendChild(element);
        element.click();
    };

    /**
     * Handles changes to the license code input.
     * 
     * @param value - The new license code value
     */
    const handleLicenseCodeChange = (value: string) => {
        setLicenseCode(value.toUpperCase());
        setKey(null); // Reset the license key when the input changes
    };

    return (
        <Box width={1}>
            <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('licenseGenerator.title')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap:2, paddingBottom: 2 }}>
       
                    <FormControl sx={{ flexGrow: 1 }}>
                        <InputLabel>{t('licenseGenerator.product')}</InputLabel>
                        <Select
                            defaultValue={undefined}
                            value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}
                            label={t('licenseGenerator.product')}
                        >
                            <MenuItem  value={undefined}>-</MenuItem>
                            {licenseGeneratorVersions.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                    {product.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
             
                {variants.length > 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, paddingBottom: 2 }}>
                        <FormControl sx={{ flexGrow: 1 }}>
                            <InputLabel>{t('licenseGenerator.variant')}</InputLabel>
                            <Select
                                value={modus}
                                onChange={(e) => setModus(e.target.value)}
                                label={t('licenseGenerator.variant')}
                            >
                                <MenuItem value={undefined}>{t('licenseGenerator.default')}</MenuItem>
                                {variants.map((variant) => (
                                    <MenuItem key={variant.tag} value={variant.tag}>
                                        {variant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                <IMaskInput
                        mask="0000-0000-0000"
                        definitions={{
                            '0': /[A-Fa-f0-9]/
                        }}
                        value={licenseCode}
                        onAccept={(value: any) => handleLicenseCodeChange(value)}
                        overwrite
                        placeholder={t('licenseGenerator.code')}
                        style={{ flexGrow: 1, padding: '16.5px 14px', fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px' }}
                    />
               </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button variant="contained" onClick={() => getLicenseData(licenseCode)}>
                        {t('licenseGenerator.generate')}
                    </Button>
                    {key && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, flexDirection: 'column', gap: 2 }}>
                            <Typography variant="body1" sx={{ marginRight: 1 }}>
                                {t('licenseGenerator.generatedKey')}: {key}
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() => downloadKeyAsFile(key)}
                            >
                                {t('licenseGenerator.download')}
                            </Button>
                            <QRCodeSVG value={key} /> {/* QR code containing the license key */}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
};

