import {useDocumentTitle} from "../../Hooks/useDocumentTitle";
import {useApplicationContext} from "../../ApplicationContext";
import React, {useCallback, useEffect, useState} from "react";
import {GetSupportContractData} from "../../apiClient/Api";
import {DataGrid, GridActionsCellItem, GridColDef, GridComparatorFn, GridRowParams} from "@mui/x-data-grid";
import {
    Edit,
    Groups,
    Support, QrCode2
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {Button, darken, lighten, styled, TableContainer} from "@mui/material";
import {DeleteUserDialog} from "../../Components/Admin/User/DeleteUserDialog";
import {AddSupportContractComponent} from "../../Components/Admin/SupportContracts/AddSupportContractComponent";
import dayjs from "dayjs";
import {SupportContractUsersComponent} from "../../Components/Admin/SupportContracts/SupportContractUsersComponent";
import {SupportCodeComponent} from "../../Components/Admin/SupportContracts/SupportCodeComponent";
import Paper from "@mui/material/Paper";

export const AdminSupportContracts = () => {
    useDocumentTitle("base.pages.supportContracts")

    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

    const StyledDataGrid = styled(DataGrid)(({theme}) => ({
        '& .super-app-theme--3': {
            backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.info.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--2': {
            backgroundColor: getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--1': {
            backgroundColor: getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--0': {
            backgroundColor: getBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
    }));


    const context = useApplicationContext();
    const [supportContracts, setSupportContracts] = useState<GetSupportContractData[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [newUserOpen, setNewUserOpen] = useState(false);
    const [deleteUserID, setDeleteUserId] = useState<undefined | string>(undefined);
    const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
    const [selectedIdContract, setSelectedIdContract] = useState<string | undefined>(undefined)
    const [selectedIdUsers, setSelectedIdUsers] = useState<string | undefined>(undefined)

    const closeSupportCode = useCallback(() => {
        setSelectedIdContract(undefined);
    }, [selectedIdContract])
    const closeNewUserCallback = useCallback((created: boolean) => {
        setNewUserOpen(false);
        setSelectedId(undefined)
        if (created) {
            updateSupportContractData();
        }
    }, [newUserOpen])

    const closeSupportContractUsers = useCallback((created: boolean) => {
        setSelectedIdUsers(undefined)
        if (created) {
            updateSupportContractData();
        }
    }, [newUserOpen])

    const userDeletedCallback = useCallback((deleted: boolean) => {
        setDeleteUserId(undefined);
        if (deleted) {
            updateSupportContractData();
        }
    }, [deleteUserID])

    const EditToolbar = () => <Button onClick={() => {
        context.api.supportContract.supportContractSync().then(() =>
            updateSupportContractData()
        ).catch(context.apiError)
    }} startIcon={<Support/>}>Wartungsverträge Synchronisieren</Button>

    const updateSupportContractData = () => {
        setIsFetching(true)
        context.api.supportContract.supportContractListSupportContracts().then(usersdata => {
            setSupportContracts(usersdata.data);
            setIsFetching(false)
        }).catch(context.apiError)
    }


    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            updateSupportContractData()
        }
    }, [initialized, supportContracts])


    const convertStateToInt = (data: string) => {
        if (data == "Lang" || data == "3")
            return 3;
        if (data == "Standard" || data == "2")
            return 2;
        if (data == "Kurz" || data == "1")
            return 1;
        if (data == "Abgelaufen" || data == "0")
            return 0;
        return -1;
    }

    const stateComparator: GridComparatorFn<string> = (v1, v2) => {
        var d1 = convertStateToInt(v1);
        var d2 = convertStateToInt(v2);
        return d1 - d2;
    }
    const convertFinishToInt = (finish: string): number => {
        if (finish == "Unbegrenzt" || finish == "KEIN ABLAUF")
            return dayjs("2100-12-31").unix();
        return dayjs(finish, "DD.MM.YYYY").unix();
    }

    const dateComparator: GridComparatorFn<string> = (v1, v2): number => {
        var d1 = convertFinishToInt(v1);
        var d2 = convertFinishToInt(v2);
        return d1 - d2;
    }

    const columns: GridColDef[] = [
        {field: "customerId", headerName: "KdNr", width: 70},
        {field: "contractId", headerName: "Vertragsnummer", width: 120},
        {field: "title", headerName: "Titel", flex: 1},
        {
            field: "status", headerName: "Status", width: 150, valueGetter: (params) => {

                switch (params.value) {
                    case 3:
                        return "Lang";
                    case 2:
                        return "Standard";
                    case 1:
                        return "Kurz";
                    case 0:
                        return "Abgelaufen";
                    default:
                        return "-";
                }

            },
            align: "center",
            headerAlign: "center",
            sortComparator: stateComparator
        },
        {field: "customerName", headerName: "Kunde", flex: 1},
        {
            field: "expire", headerName: "Ablauf", width: 100, valueGetter: (params) => {
                if (params.row.infinite)
                    return "Unbegrenzt";
                if (params.value == undefined) {
                    return "KEIN ABLAUF"
                }
                const date = dayjs(params.value.toString()) // not MM-DD-YY
                return date.format("DD.MM.YYYY")
            },
            sortComparator: dateComparator
        },
        {field: "filterTagsCount", headerName: "Komponenten", width: 100, align: "center"},
        {
            field: 'actions',
            type: 'actions',
            width: 150,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label={"Nutzer bearbeiten"} onClick={() => {
                    setSelectedId(params.row.id);
                    setNewUserOpen(true);
                }} icon={<Edit/>}/>,
                <GridActionsCellItem label={"Nutzer"} onClick={() => {
                    setSelectedIdUsers(params.row.id)
                }} icon={<Groups/>}/>,
                <GridActionsCellItem label={"Nutzer löschen"} disabled={(context.userData!.userGroup ?? 4) >= 2}
                                     onClick={() => {
                                         setDeleteUserId(params.row.id);

                                     }} icon={<DeleteIcon/>}/>,
                <GridActionsCellItem label={"Nutzer löschen"} disabled={(context.userData!.userGroup ?? 4) >= 2}
                                     onClick={() => {
                                         // open window to manage support codes
                                         setSelectedIdContract(params.row.id)
                                     }} icon={<QrCode2/>}/>,
            ]
        }
    ]

    return <>
        <SupportContractUsersComponent closeComponent={closeSupportContractUsers} supportId={selectedIdUsers}/>
        <AddSupportContractComponent open={newUserOpen} closeComponent={closeNewUserCallback} supportId={selectedId}/>
        <SupportCodeComponent supportId={selectedIdContract} closeComponent={closeSupportCode}/>
        <DeleteUserDialog userDeleted={userDeletedCallback} id={deleteUserID}/>
        <TableContainer component={Paper} style={{margin: 4}}>
            <StyledDataGrid
                slots={{
                    toolbar: EditToolbar,
                }}
                getRowClassName={(params) => `super-app-theme--${params.row.status}`}

                rows={supportContracts}
                columns={columns}
                loading={isFetching}
                autoHeight={true}></StyledDataGrid>
        </TableContainer>
    </>
}
