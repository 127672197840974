import React, {useContext, useEffect, useState} from "react";
import {useApplicationContext} from "../ApplicationContext";
import {useTranslation} from "react-i18next";

const useDocumentTitle = (title : string) => {
    const context = useApplicationContext();
    const {t, i18n} = useTranslation();
    useEffect(() => {
        context.setTitle(t(title));
    },[context.pageTitle, i18n.language, title]);
};

export {useDocumentTitle};
