import {BlockTypeSelect, headingsPlugin, ListsToggle} from '@mdxeditor/editor'
import { MDXEditor, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, listsPlugin, linkPlugin, DiffSourceToggleWrapper,diffSourcePlugin } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import React, {useEffect, useState, useMemo, DragEvent} from "react";
import {Box, TextField, MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText, SelectChangeEvent, Button, Typography, Grid, IconButton, List, ListItem, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import {useApplicationContext}from "../../ApplicationContext";
import {GetFileTypeData, GetFilterTagData, GetHostSystemData, ImageDto, AddFileTypeData, FileTypeVariantsDto, FileTypeClass}from "../../apiClient/Api";
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import iconPlaceholder from './icon_placeholder.svg';
import IconPlaceholder from './IconPlaceholder';
import ScreenshotPlaceholder from './ScreenshotPlaceholder';
import PhonePlaceholder from './PhonePlaceholder';
import { useFileTypeClassTranslations } from 'Hooks/FileTypeClass';

function FileTypeEdit() {
    const context = useApplicationContext();
    const controller = context.api.fileType;
    const { id } = useParams<{ id: string }>();
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const [hostSystems, setHostSystems] = useState<GetHostSystemData[]>([]);
    const [filterTags, setFilterTags] = useState<GetFilterTagData[]>([]);
    const [fileTypes, setFileType] = useState<GetFileTypeData|null>(null);
    const [value, setValue] = useState<string>('');
    const [icon, setIcon] = useState<File | null>(null);
    const [images, setImages] = useState<{ file: File, description: string }[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [logo, setLogo] = useState<ImageDto | null>(null);

    const fileTypeTranslations = useFileTypeClassTranslations();

    const [newVariant, setNewVariant] = useState<FileTypeVariantsDto>({ name: '', tag: '' });

    const [screenshots, setScreenshots] = useState<ImageDto[]>();

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const logoUrl = useMemo(() => {
            // @ts-ignore
        var serverUrl = ServerConfig.SERVER_URL;
        if (logo) {
            return `${serverUrl}/FileType/Image/${logo.id}`;
        }
        

        return null;
    }, [logo]);

    const initialMarkdown = useMemo(() => {
        return fileTypes?.description || '';
    }, [fileTypes]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setValue(initialMarkdown);
    }, [initialMarkdown]);

    async function updateLogoData() {
        var logoData = await controller.fileTypeGetLogo(id!);

        setLogo(logoData.data);
    }

    async function updateScreenshotData() {
        var logoData = await controller.fileTypeGetScreenshots(id!);
        setScreenshots(logoData.data);
    }

    async function fetchData() {
        setIsFetching(true)
        try {
            var hostSystems = await controller.fileTypeGetHostSystems();
            setHostSystems(hostSystems.data);
            var filterTags = await context.api.filterTags.filterTagsListFilterTags();
            setFilterTags(filterTags.data);

            if(id == undefined){
                setFileType(null)
            }
            else{
                var value = await controller.fileTypeGetFileType(id);
                setFileType(value.data)    
            }

            await updateLogoData();

            await updateScreenshotData();
                
        } catch (e) {
            context.apiError(e)
        }
        setIsFetching(false)
    }

    const handleDescriptionChange = (newValue: string) => {
        setValue(newValue);
        if (fileTypes) {
            setFileType({ ...fileTypes, description: newValue });
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fileTypes) {

            setFileType({ ...fileTypes, name: event.target.value });
        }
    };

    const handleHostSystemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fileTypes) {
            setFileType({ ...fileTypes, hostSystemId: event.target.value });
        }
    };

    const handleFilterTagsChange = (event: SelectChangeEvent<(string | undefined)[]>) => {
        const value = event.target.value as string[];
        const selectedTags = filterTags.filter(tag => value.includes(tag.identifier || ''));
        if (fileTypes) {
            setFileType({ ...fileTypes, filterTags: selectedTags });
        }
    };

    const handleMatchingFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fileTypes) {
            setFileType({ ...fileTypes, matchingFiles: event.target.value.split('\n').map(file => file.trim()) });
        }
    };

    const handlePackageIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (fileTypes) {
            setFileType({ ...fileTypes, packageId: event.target.value });
        }
    };

    const handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        var intNumber = parseInt(event.target.value);
        if (fileTypes) {
            setFileType({ ...fileTypes, version: intNumber });
        }
    };


    const handleNewVariantChange = (field: 'name' | 'tag', value: string) => {
        setNewVariant({ ...newVariant, [field]: value });
    };

    const addVariant = () => {
        setFileType(ft => ft != null ? ({ ...ft, variants: [...fileTypes?.variants ?? [], newVariant ] }) : null);
        setNewVariant({ name: '', tag: '' });
    };

    const removeVariant = (tag: String | undefined) => {
        if(tag){
            setFileType(ft => ft != null ? ({ ...ft, variants: [...(fileTypes?.variants ?? []).filter(f => f.tag !== tag)] }) : null);
        }
    };

    const handleImageDelete = async (index: number) => {
        const imageToDelete = screenshots![index];
        await controller.fileTypeDeleteImage(imageToDelete.id!);
        setScreenshots(screenshots!.filter((_, i) => i !== index));
    };

    const handleImageDescriptionChange = async (index: number, description: string) => {
        const updatedScreenshots = [...screenshots!];
        updatedScreenshots[index].description = description;
        setScreenshots(updatedScreenshots);
        await controller.fileTypeEditImageDescription(updatedScreenshots[index].id!, description);
    };

    const uploadIcon = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        await controller.fileTypeAddLogo(id!, {
            File: file,
            Description: "Icon"
        });

        await updateLogoData();
    };

    const uploadImages = async (files: { file: File, description: string }[]) => {
        for (const { file } of files) {
            const response = await controller.fileTypeAddScreenshot(id!, {
                File: file,
                Description: "Screenshot"
            });
            setScreenshots(prevScreenshots => [...prevScreenshots!, response.data]);
        }
    };

    const deleteImage = async (file: File) => {
        await controller.fileTypeDeleteImage(id!);
    };

    const handleIconChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = async () => {
                if (img.width === img.height && img.width <= 1024 && img.height <= 1024) {
                    setIcon(file);
                    await uploadIcon(file);
                } else {
                    alert("Icon must be a square and not exceed 1024x1024 pixels.");
                }
            };
        }
    };

    const handleImagesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = Array.from(event.target.files).map(file => ({ file, description: '' }));
            setImages(prevImages => [...prevImages, ...files]);
            await uploadImages(files);
        }
    };

    const handleIconDrop = async (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            const file = event.dataTransfer.files[0];
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = async () => {
                if (img.width === img.height && img.width <= 1024 && img.height <= 1024) {
                    setIcon(file);
                    await uploadIcon(file);
                } else {
                    alert("Icon must be a square and not exceed 1024x1024 pixels.");
                }
            };
        }
    };

    const handleImagesDrop = async (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        if (event.dataTransfer.files) {
            const files = Array.from(event.dataTransfer.files).map(file => ({ file, description: '' }));
            setImages(prevImages => [...prevImages, ...files]);
            await uploadImages(files);
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
    };

    const convertImageDtoToUrl = (imageDto: ImageDto): string => {
        // @ts-ignore
        var serverUrl = ServerConfig.SERVER_URL;
        return `${serverUrl}/FileType/Image/${imageDto.id}`;
    };

    const renderSelectedTags = (selected: (string | undefined)[]) => {
        return selected
            .map(identifier => filterTags.find(tag => tag.identifier === identifier)?.name)
            .filter(name => name !== undefined)
            .join(', ');
    };

    const renderImagePreviews = () => {
        return screenshots!.map((image, index) => (
            <Box key={index} sx={{ margin: 1, position: 'relative', border: '1px solid grey', borderRadius: '4px' }}>
                <img src={convertImageDtoToUrl(image)} alt={`preview-${index}`} width="100" />
                <IconButton
                    onClick={() => handleImageDelete(index)}
                    sx={{ position: 'absolute', top: 0, right: 0, minWidth: '24px', minHeight: '24px', padding: 0 }}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </IconButton>
                <TextField
                    value={image.description || ''}
                    onChange={(e) => handleImageDescriptionChange(index, e.target.value)}
                    placeholder="Description"
                    variant="outlined"
                    size="small"
                    sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                />
            </Box>
        ));
    };

    /**
     * Handles the save operation for the file type.
     * @async
     */
    const handleSave = async () => {
        if (fileTypes) {
            const data: AddFileTypeData = {
                hostSystemId: fileTypes.hostSystemId,
                name: fileTypes.name,
                description: fileTypes.description,
                filterTags: fileTypes.filterTags?.map(tag => tag.identifier || '') ?? [],
                packageId: fileTypes.packageId,
                variants: fileTypes.variants,
                version: fileTypes.version,
                type: fileTypes.type,
                public: fileTypes.public,
            };
            await controller.fileTypeEditFileType(id!, data);
            setIsDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    if(isFetching)
        return <Box>Loading...</Box>

    if(fileTypes == null)
        return <Box>File type not found</Box>

    return <Box sx={{width: "100%"}}>
        <TextField
            label="Name"
            value={fileTypes.name || ''}
            onChange={handleNameChange}
            fullWidth
            margin="normal"
        />

        <Select  label="Art" fullWidth value={fileTypes.type ?? FileTypeClass.UNDEFINED} onChange={(e) => setFileType({ ...fileTypes, type: e.target.value as string as FileTypeClass })}>
            {fileTypeTranslations.map((fileType) => (<MenuItem value={fileType.id}>{fileType.name}</MenuItem>))}
        </Select>

         <FormControlLabel control={<Switch checked={fileTypes.public} onChange={(checked) => {
            setFileType((ft) => {
                if(ft != null){
                    return {...ft, public: checked.target.checked}
                    
                }
                return null;
            });
         }} />} label="Öffentlich anzeigen" />
       
        <TextField
            label="Host System"
            value={fileTypes.hostSystemId || ''}
            onChange={handleHostSystemChange}
            select
            fullWidth
            margin="normal"
        >
            {hostSystems.map((hostSystem) => (
                <MenuItem key={hostSystem.id} value={hostSystem.id}>
                    {hostSystem.name}
                </MenuItem>
            ))}
        </TextField>
        <FormControl fullWidth margin="normal">
            <InputLabel>Filter Tags</InputLabel>
            <Select
                multiple
                value={fileTypes.filterTags?.map(tag => tag.identifier) || []}
                onChange={handleFilterTagsChange}
                renderValue={renderSelectedTags}
            >
                {filterTags.map((filterTag) => (
                    <MenuItem key={filterTag.identifier} value={filterTag.identifier}>
                        <Checkbox checked={fileTypes.filterTags?.some(tag => tag.identifier === filterTag.identifier) || false} />
                        <ListItemText primary={filterTag.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <TextField
            label="Matching Files"
            value={fileTypes.matchingFiles?.join('\n') || ''}
            onChange={handleMatchingFilesChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
        />
        <TextField
            label="Package ID"
            value={fileTypes.packageId || ''}
            onChange={handlePackageIdChange}
            fullWidth
            margin="normal"
        />
        {/* Lizenz Modus zur Freischaltung der Software*/}
        <TextField
            label="Version"
            value={fileTypes.version || 1}
            onChange={handleVersionChange}
            fullWidth
            type="number"
            inputMode='decimal'
            margin="normal"
        />        
        
        <Box sx={{ marginY: 2 }}>
            <Typography variant="h6">Varianten</Typography>
            <List>
                {fileTypes.variants?.map((variant, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={variant.name} secondary={variant.tag} />
                        <IconButton onClick={() => removeVariant(variant.tag)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <TextField
                    label="Tag"
                    value={newVariant.tag}
                    onChange={(e) => handleNewVariantChange('tag', e.target.value)}
                    sx={{ marginRight: 2 }}
                />
                <TextField
                    label="Name"
                    value={newVariant.name}
                    onChange={(e) => handleNewVariantChange('name', e.target.value)}
                    sx={{ marginRight: 2 }}
                />
                <Button variant="outlined" onClick={addVariant}>Add Variant</Button>
            </Box>
        </Box>

        <Box sx={{ marginY: 2 }}>
            <Typography variant="h6">Icon</Typography>
            <Box
                sx={{
                    border: '2px dashed grey',
                    padding: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: isDragging ? '0 0 10px 2px rgba(0, 0, 0, 0.2)' : 'none'
                }}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleIconDrop}
                component="label"
            >
                {logoUrl ? (
                    <img src={logoUrl} alt="icon-preview" width="100" style={{ borderRadius: '10%' }} />
                ) : (
                    <IconPlaceholder/>
                )}
                <input type="file" hidden onChange={handleIconChange} />
            </Box>
        </Box>
        <Box sx={{ marginY: 2 }}>
            <Typography variant="h6">Screenshots</Typography>
            <Box
                sx={{
                    border: '2px dashed grey',
                    padding: 2,
                    textAlign: 'center',
                    cursor: 'pointer',
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: isDragging ? '0 0 10px 2px rgba(0, 0, 0, 0.2)' : 'none'
                }}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleImagesDrop}
                component="label"
            >
                {screenshots && screenshots.length > 0 ? (
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        {renderImagePreviews()}
                    </Grid>
                ) : (
                    <Grid container spacing={2} sx={{ marginTop: 2, alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item>
                            <ScreenshotPlaceholder />
                        </Grid>
                        <Grid item>
                            <PhonePlaceholder />
                        </Grid>
                    </Grid>
                )}
                <input type="file" hidden multiple onChange={handleImagesChange} />
            </Box>
        </Box>
        <MDXEditor
            markdown={fileTypes.description!}
            onChange={handleDescriptionChange}
            plugins={[
                listsPlugin(),
                diffSourcePlugin(),
                headingsPlugin(),
                linkPlugin(),
                toolbarPlugin({
                    toolbarClassName: 'my-classname',
                    toolbarContents: () => (
                        <>
                            <DiffSourceToggleWrapper options={["rich-text", "source"]}>
                                {' '}
                                <UndoRedo />
                                <BlockTypeSelect/>
                                <BoldItalicUnderlineToggles />
                                <ListsToggle />
                            </DiffSourceToggleWrapper>
                        </>
                    )
                })
            ]}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 2 }}
        >
            Save
        </Button>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    File type saved successfully!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default FileTypeEdit
