import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, {ReactNode} from 'react';
import {
    HashRouter as Router,
    Routes,
    NavLink,
    NavLinkProps,
} from 'react-router';
import {LinkRounded} from "@mui/icons-material";

type RouterLinkProps = React.PropsWithChildren<{
    to: string,
    text: string,
    icon?: ReactNode
}>

export const RouterLink = (props: RouterLinkProps) => {
    type MyNavLinkProps = Omit<NavLinkProps, 'to'>;
    const MyNavLink = React.useMemo(() => React.forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => {
        const {className: previousClasses, ...rest} = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? "";

        return (<NavLink
            {...rest}
            ref={ref}
            to={props.to}
            end
            className={({isActive}) => (isActive ? elementClasses + " Mui-selected" : elementClasses)}
        />)
    }), [props.to]);

    return (
        <ListItemButton
            component={MyNavLink}
        >
                <ListItemIcon sx={{'.Mui-selected > &': {color: (theme) => theme.palette.primary.main}}}>
                    {props.icon != undefined ? props.icon : <LinkRounded />}
                </ListItemIcon>

            <ListItemText primary={props.text}/>
        </ListItemButton>
    )
}
