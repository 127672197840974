import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../ApplicationContext";
import {GetFilterTagData} from "../../apiClient/Api";


export const FilterTags = () => {

    const context = useApplicationContext();
    const [initialized, setInitialized] = useState(false);
    const [filterTags, setFilterTags] = useState<GetFilterTagData[]>([])

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            context.api.filterTags.filterTagsListFilterTags().then(tags => {
                if (tags.data != undefined) {
                    setFilterTags(tags.data);
                }
            }).catch(context.apiError)
        }
    }, [])

    return <>
        <Typography>
            <Typography variant="h2">Dateifilter</Typography><br/>
            <Typography variant="caption">Dateifilter werden automatisch generiert und können nicht manuell bearbeitet
                werden</Typography>
        </Typography>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="dateifilter-tabelle">
                <TableHead >
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>
                            ID
                        </TableCell>
                        <TableCell style={{fontWeight: "bold"}}>
                            Text
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filterTags.map(tag => <TableRow key={tag.identifier}>
                        <TableCell>{tag.identifier}</TableCell>
                        <TableCell>{tag.name}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
