import {AppBar, Backdrop, Box, Button, CircularProgress, IconButton, Toolbar, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../ApplicationContext";
import {GetTermsData} from "../../apiClient/Api";
import {MuiMarkdown} from "mui-markdown";
import Grid from "@mui/material/Grid";

export const AcceptTerms = () => {
    const context = useApplicationContext();
    const [latestTerms, setLatestTerms] = useState<GetTermsData | undefined>()
    const [initialized, setInitialized] = useState(false)
    const theme = useTheme();

    useEffect(() => {
        if (!initialized) {
            setInitialized(true)
            context.api.user.userAcceptedTerms({}).then(data => {
                if (data && data.data != undefined) {
                    if (!data.data) {
                        context.api.terms.termsGetLatestTerms().then(data => {
                            setLatestTerms(data.data)
                        }).catch(context.apiError);
                    }
                    context.setAcceptedTerms(data.data)
                }
            }).catch(context.apiError)
        }
    }, [initialized, latestTerms])

    return <>
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                    >

                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        {latestTerms == undefined ? <> Reichert Support System</> : <> Reichert Support System -
                            Bedingungen</>}
                    </Typography>
                    <Button color="inherit" onClick={context.signOut}>Ausloggen</Button>
                </Toolbar>
            </AppBar>
        </Box>

        <Box flexGrow={1} paddingTop={3} paddingLeft={3} paddingRight={3}>
            {latestTerms != undefined && <>
                <Typography variant={"h2"} color={"primary"}
                            textAlign={"left"}>{latestTerms.versionTitle!}</Typography>
                <Typography variant={"subtitle1"} textAlign={"left"} color={"error"}>Zur Nutzung des Systems müssen
                    Sie vorab folgende Bedingungen akzeptieren</Typography>
                <div style={{textAlign: "left"}}>
                    <MuiMarkdown overrides={context.muiMarkdownOverrides}>{latestTerms.content!}</MuiMarkdown>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button size={"large"} fullWidth variant={"contained"} color={"success"}  style={{marginTop: 20}}
                                onClick={context.acceptTerms}>Bedingungen Akzeptieren</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button size={"large"} fullWidth variant={"contained"} style={{marginTop: 20}} color={"error"}
                                onClick={() => context.setDisplayAcceptTerms(false)}>Bedingungen Ablehnen</Button>
                    </Grid>
                </Grid>


            </>}

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={latestTerms == undefined}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </Box>
    </>
}
