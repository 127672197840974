import {DataGrid, GridActionsCellItem, GridRowParams, GridValueFormatterParams} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TableContainer,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {AutoAwesome, CheckCircle, Close, CreateNewFolder, Edit, StopCircle, UploadFile} from "@mui/icons-material";
import {useDocumentTitle} from "../../Hooks/useDocumentTitle";
import {useApplicationContext} from "../../ApplicationContext";
import {AddVersionData, EUserGroup, EVersionStage, GetProductsData, GetVersionData} from "../../apiClient/Api";
import {UploadVersionFileDialog} from "../../Components/UploadVersionFileDialog";
import {format, parseISO} from "date-fns";
import Paper from "@mui/material/Paper";
import GetEnumKeys from "../../GetEnumKeys";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useParams} from "react-router";
import {AutoUploadVersionDialog} from "../../Components/AutoUploadVersionDialog";


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

type RouteParams = {
    link: string
}
export const AdminVersions = () => {
    useDocumentTitle("base.pages.versions")
    const params = useParams<RouteParams>()
    const [data, setData] = useState<GetVersionData[]>([])
    const [product, setProduct] = useState<GetProductsData>()
    const [currentVersion, setCurrentVersionData] = useState<GetVersionData | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0)
    const [currentSelectedVersion, setCurrentSelectedVersion] = useState<{
        major: number,
        minor: number,
        patch: number,
        productId: string
    } | undefined>(undefined)
    const [currentSelectedVersionAutoUpload, setCurrentSelectedVersionAutoUpload] = useState<{
        major: number,
        minor: number,
        patch: number,
        productId: string
    } | undefined>(undefined)
    const context = useApplicationContext();
    var controller = context.api.version;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const resetSelectedVersion = useCallback(() => {
        setCurrentSelectedVersion(undefined)
        setCurrentSelectedVersionAutoUpload(undefined)
    }, [currentSelectedVersion, currentSelectedVersionAutoUpload])

    const handleSave = async () => {
        if (currentVersion != null && product != null) {
            if (currentVersion.major != undefined && currentVersion.minor != undefined && currentVersion.patch != undefined) {
                var newVersion: AddVersionData = {
                    criticalUpdate: "",
                    stage: currentVersion.stage,
                    versionKey: {
                        major: currentVersion.major,
                        minor: currentVersion.minor,
                        patch: currentVersion.patch,
                        productId: product.id!
                    },
                    description: currentVersion.description,
                    changes: currentVersion.changes,
                    internalInformation: currentVersion.internalInformation,
                    approved: currentVersion.approved,
                    header: currentVersion.header,
                    footer: currentVersion.footer
                }

                if (isNew) {
                    var value = await controller.versionAddVersion(newVersion)
                    fetchData();
                    setOpen(false)
                } else {
                    if (currentVersion != null) {
                        var value = await controller.versionUpdateVersion(newVersion)
                        setOpen(false)
                    }
                }
            }
        }
    };

    async function fetchData() {
        setIsFetching(true)
        if (params.link) {
            var products = await context.api.products.productsGetProductByLink(params.link!)
            var value = await controller.versionGetVersionsFlat(params.link!)
            setData(value.data)
            setProduct(products.data)
        }
        setIsFetching(false)
    }

    useEffect(() => {
            fetchData();
    }, [params.link])

    const EditToolbar = () => <Button onClick={() => {
        setCurrentVersionData({
            criticalUpdate: undefined,
            files: [],
            internalInformation: "",
            header: null,
            footer: null,
            stage: EVersionStage.INTERNAL,
            versionId: "",
            major: 0,
            minor: -1,
            patch: -1,
            subtitle: "",
            description: "",
            changes: {
                bugfixes: [],
                features: [],
                highlights: [],
            },
            approved: undefined
        })
        setCounter(0);
        setOpen(true);
        setIsNew(true);
    }} startIcon={<CreateNewFolder/>}>Neue Version hinzufügen</Button>

    return <>
        <Typography textAlign={"center"} width={"100%"}>
            <Typography variant={"h2"} color={"primary"} fontSize={18}>{product?.name}</Typography>
            <Typography variant={"h3"} color={"secondary"} fontSize={15}
                        fontWeight={"bold"}>{product?.description}</Typography>
        </Typography>

        <TableContainer component={Paper} style={{margin: 4}}>
            <DataGrid getRowId={(row) => row.versionId!}

                      slots={{
                          toolbar: EditToolbar,
                      }}


                      columns={[
                          {field: "versionId", headerName: "Version"},
                          {field: "subtitle", headerName: "Beschreibung", flex: 1},
                          {
                              field: "approved",
                              width: 130,
                              headerName: "Freigegeben",
                              valueFormatter: (params: GridValueFormatterParams<string | undefined>) => {
                                  if (params.value == undefined) {
                                      return "Nicht freigeben"
                                  }
                                  const date = parseISO(params.value.toString()) // not MM-DD-YY
                                  return format(date, "dd.MM.yyyy")
                              }

                          },
                          {
                              field: 'actions',
                              type: 'actions',
                              width: 160,
                              getActions: (params: GridRowParams) => [
                                  <GridActionsCellItem label={"Edit"} onClick={() => {
                                      setCurrentVersionData(params.row)
                                      setOpen(true);
                                      setIsNew(false);
                                  }} icon={<Edit/>}/>,
                                  <GridActionsCellItem label={"Automatischer Upload"} onClick={() => {
                                      setCurrentSelectedVersionAutoUpload({
                                          major: params.row.major,
                                          minor: params.row.minor,
                                          patch: params.row.patch,
                                          productId: params.row.productId
                                      });
                                  }} icon={<AutoAwesome/>}/>,
                                  <GridActionsCellItem label={"Datei hinzufügen"} onClick={() => {
                                      setCurrentSelectedVersion({
                                          major: params.row.major,
                                          minor: params.row.minor,
                                          patch: params.row.patch,
                                          productId: params.row.productId
                                      });
                                  }} icon={<UploadFile/>}/>,
                                  <>
                                      {params.row.approved == undefined ?
                                          <GridActionsCellItem label={"Freigeben"} onClick={() => {
                                              controller.versionApproveVersion({
                                                  major: params.row.major,
                                                  minor: params.row.minor,
                                                  patch: params.row.patch,
                                                  productId: params.row.productId
                                              }).then(test => {
                                                  fetchData();
                                              }).catch(context.apiError)
                                          }} icon={<CheckCircle/>}/> :
                                          <GridActionsCellItem label={"Freigabe aufgeben"} onClick={() => {
                                              controller.versionRevokeVersion({
                                                  major: params.row.major,
                                                  minor: params.row.minor,
                                                  patch: params.row.patch,
                                                  productId: params.row.productId
                                              }).then(test => {
                                                  fetchData();
                                              }).catch(context.apiError)
                                          }} icon={<StopCircle/>}/>}
                                  </>

                              ]
                          }
                      ]}
                      rows={data} loading={isFetching} autoHeight={true}/>
        </TableContainer>
        <UploadVersionFileDialog currentVersion={currentSelectedVersion} resetSelectedVersion={resetSelectedVersion}/>
        <AutoUploadVersionDialog currentVersion={currentSelectedVersionAutoUpload} resetSelectedVersion={resetSelectedVersion}/>
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {isNew ? "Neue Version" : currentVersion?.versionId}
                    </Typography>

                </Toolbar>
            </AppBar>

            <DialogContent>

                {isNew && <>
                    <Typography color={"primary"} style={{marginTop: 5, marginBottom: 10, textDecoration: "underline"}}
                                variant={"h6"}>Version eingeben</Typography>
                    <Box component={"form"} style={{display: "flex", alignItems: "center"}}>

                        <Input placeholder={"Major"} type={"number"} value={currentVersion?.major} onChange={target => {
                            setCurrentVersionData(val => {
                                if (val != undefined) {
                                    val.major = parseInt(target.target.value);
                                    if (val.major < 0)
                                        val.major = 0;
                                }
                                return val;
                            })
                            setCounter(counter + 1)
                        }} style={{width: 70, marginRight: 10, textAlign: "center"}}/>
                        <Input placeholder={"Minor"} type={"number"} value={currentVersion?.minor} onChange={target => {
                            setCurrentVersionData(val => {
                                if (val != undefined) {
                                    val.minor = parseInt(target.target.value);
                                    if (val.minor < -1)
                                        val.minor = -1;
                                }
                                return val;
                            })
                            setCounter(counter + 1)
                        }} style={{width: 70, marginRight: 10, textAlign: "center"}}/>
                        <Input placeholder={"Patch"} type={"number"} value={currentVersion?.patch} onChange={target => {
                            setCurrentVersionData(val => {
                                if (val != undefined) {
                                    val.patch = parseInt(target.target.value);
                                    if (val.patch < -1)
                                        val.patch = -1;
                                }
                                return val;
                            })
                            setCounter(counter + 1)
                        }} style={{width: 70, marginRight: 10, textAlign: "center"}}/>
                        <br/>
                    </Box></>}

                <FormControl fullWidth style={{marginTop: 20}}>
                    <InputLabel id="version-type-label">Typ</InputLabel>
                    <Select
                        labelId="version-type-label"
                        label="Typ"
                        value={currentVersion?.stage}
                        onChange={event => {
                            setCurrentVersionData(version => {
                                    if (version != undefined) {
                                        var id = (event.target.value);
                                        if (typeof id == "number") {
                                            var idnum = id;
                                            version.stage = idnum
                                        }


                                    }
                                    return version;
                                }
                            );
                            setCounter(counter + 1)
                        }}


                    >
                        {GetEnumKeys(EVersionStage).map((a, b) => {
                            return <MenuItem key={a} value={b}>{a}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth style={{marginTop: 20}}>
                    <DatePicker label={"Freigabedatum"}
                                value={currentVersion?.approved ? dayjs(currentVersion?.approved) : null}
                                onChange={(newValue) => {
                                    setCurrentVersionData(version => {
                                        if (version != undefined && version != null) {
                                            if (newValue == null)
                                                version.approved = newValue;
                                            else
                                                version.approved = newValue.format("YYYY-MM-DD")
                                        }

                                        return version;
                                    })
                                }}/>
                </FormControl>

                <DialogContentText>
                    {currentVersion?.subtitle}
                </DialogContentText>
                <Typography color={"primary"} style={{marginTop: 20, marginBottom: 10, textDecoration: "underline"}}
                            variant={"h6"}>Inhalte</Typography>

                <TextField fullWidth label={"Kritische Version"}
                           value={currentVersion?.criticalUpdate}
                           onChange={event => {
                               setCurrentVersionData(version => {
                                   if (version != null)
                                       version.criticalUpdate = event.target.value;
                                   return version;
                               })
                               setCounter(counter + 1)
                           }}/>

                <TextField
                    id="standard-multiline-static"
                    label="Kopfbereich"
                    multiline
                    style={{marginTop: 10}}
                    fullWidth={true}
                    rows={4}
                    onChange={event => {
                        var value = event.target.value
                        if (value != undefined) {
                            setCurrentVersionData(oldValue => {
                                if (oldValue != null) {
                                    oldValue.header = value;
                                }
                                return oldValue;
                            })
                            setCounter(counter + 1)
                        }

                    }}
                    value={currentVersion?.header}
                    variant="outlined"
                />
                <hr/>
                <TextField
                    fullWidth
                    label="Highlights"
                    multiline
                    rows={4}
                    maxRows={30}
                    margin={"normal"}
                    value={currentVersion?.changes?.highlights?.join("\n")}
                    onChange={(event) => {
                        var value = event.target.value;
                        var values = value.split("\n");

                        setCurrentVersionData(oldValue => {
                            if (oldValue?.changes) {
                                oldValue.changes.highlights = values
                            }
                            return oldValue;
                        })
                        setCounter(counter + 1)
                    }}
                    variant="outlined"
                />

                <TextField
                    margin={"normal"}
                    fullWidth
                    label="Features"
                    multiline
                    rows={4}
                    maxRows={30}
                    value={currentVersion?.changes?.features?.join("\n")}
                    onChange={(event) => {
                        var value = event.target.value;
                        var values = value.split("\n");

                        setCurrentVersionData(oldValue => {
                            if (oldValue?.changes) {
                                oldValue.changes.features = values
                            }
                            return oldValue;
                        })
                        setCounter(counter + 1)
                    }}
                    variant="outlined"
                />

                <TextField
                    fullWidth
                    label="Bugfixes"
                    margin={"normal"}
                    multiline
                    rows={4}
                    maxRows={30}
                    value={currentVersion?.changes?.bugfixes?.join("\n")}
                    onChange={(event) => {
                        var value = event.target.value;
                        var values = value.split("\n");

                        setCurrentVersionData(oldValue => {
                            if (oldValue?.changes) {
                                oldValue.changes.bugfixes = values
                            }
                            return oldValue;
                        })
                        setCounter(counter + 1)
                    }}
                    variant="outlined"
                />
                <hr/>
                <TextField
                    id="standard-multiline-static"
                    label="Beschreibung"
                    multiline
                    fullWidth={true}
                    rows={4}
                    onChange={event => {
                        var value = event.target.value
                        if (value != undefined) {
                            setCurrentVersionData(oldValue => {
                                if (oldValue != null) {
                                    oldValue.description = value;
                                }
                                return oldValue;
                            })
                            setCounter(counter + 1)
                        }

                    }}
                    value={currentVersion?.description}
                    variant="outlined"
                />
                <TextField
                    id="standard-multiline-static"
                    label="Fußbereich"
                    multiline
                    style={{marginTop: 10}}
                    fullWidth={true}
                    rows={4}
                    onChange={event => {
                        var value = event.target.value
                        if (value != undefined) {
                            setCurrentVersionData(oldValue => {
                                if (oldValue != null) {
                                    oldValue.footer = value;
                                }
                                return oldValue;
                            })
                            setCounter(counter + 1)
                        }

                    }}
                    value={currentVersion?.footer}
                    variant="outlined"
                />

                <TextField
                    id="standard-multiline-static"
                    label="Interne Informationen"
                    multiline
                    style={{marginTop: 10}}
                    fullWidth={true}
                    rows={4}
                    onChange={event => {
                        var value = event.target.value
                        if (value != undefined) {
                            setCurrentVersionData(oldValue => {
                                if (oldValue != null) {
                                    oldValue.internalInformation = value;
                                }
                                return oldValue;
                            })
                            setCounter(counter + 1)
                        }

                    }}
                    value={currentVersion?.internalInformation}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Abbrechen
                </Button>
                <Button onClick={handleSave} autoFocus>
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>

    </>
}
