import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FileTypeClass } from '../apiClient/Api';

/**
 * Returns a list of file type classes with their translations.
 * @returns {Array<{ id: FileTypeClass, name: string }>}
 */
export function useFileTypeClassTranslations() {
  const { t } = useTranslation();

  return useMemo(() => [
    { id: FileTypeClass.UNDEFINED, name: t('fileTypeClass.undefined') },
    { id: FileTypeClass.APPLICATION, name: t('fileTypeClass.application') },
    { id: FileTypeClass.DOCUMENTATION, name: t('fileTypeClass.documentation') },
    { id: FileTypeClass.OTHER, name: t('fileTypeClass.other') },
  ], [t]);
}
