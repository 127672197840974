import {
    Box, Button,
    Card,
    CardContent,
    CardHeader,
    darken,
    Grid,
    IconButton,
    lighten,
    List, ListItem, ListItemIcon, ListItemText,
    Paper,
    Stack,
    styled,
    Toolbar,
    Tooltip
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {useDocumentTitle} from "../../Hooks/useDocumentTitle";
import {EUserGroup, GetSupportCodeData, GetSupportContractData, GetVersionData} from "../../apiClient/Api";
import {useApplicationContext} from "../../ApplicationContext";
import dayjs from "dayjs";
import {Masonry} from "@mui/lab";
import {LabelImportant} from "@mui/icons-material";
import {Storage as StorageIcon} from "@mui/icons-material";
import {useNavigate} from "react-router";
import { Buffer } from 'buffer';
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMobile,
    faLifeRing,
    faFileCertificate
    
} from '@fortawesome/pro-solid-svg-icons'
export const AdminDashboard = () => {


    useDocumentTitle("base.pages.dashboard")
    const [data, setData] = useState<GetSupportContractData[]>([])
    const [currentVersion, setCurrentVersionData] = useState<GetVersionData | null>(null);
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0)
    const context = useApplicationContext();
    var controller = context.api.version;

    async function fetchData() {
        setIsFetching(true)
        var data = await context.api.supportContract.supportContractListSupportContracts();
        if(data && data.data)
            setData(data.data)
    }

    useEffect(() => {
        if (!isData) {
            setIsData(true);
            fetchData();
        }

    }, [data, isData, isFetching])

    const getStatusColor = (status?: number) => {

        return "";
    }


    const renewContract = (id: string, duration: number) => () => {
        context.api.supportContract.supportContractRemewContract({contract: id, duration}).then(() => {
            alert("Vielen dank für Ihre Anfrage. Sie erhalten in wenigen Tagen Ihr Angebot")
        }).catch(context.apiError)
    }

    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

    const StyledCard = styled(Card)(({theme}) => ({
        '& .super-app-theme--3': {
            backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.info.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--2': {
            backgroundColor: getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--1': {
            backgroundColor: getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--0': {
            backgroundColor: getBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
    }));

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{width: '100%'}} padding={2}>
                <Masonry columns={{xs: 1, sm: 2, md: 3}} spacing={2}>
                    {data.map(elem =>
                        <StyledCard key={elem.id}>
                            <CardContent className={`super-app-theme--${elem.status}`}>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom variant="caption">
                                    {t('common.contractNumber')}
                                </Typography>
                                <Typography variant="h5" component="div" color="primary">
                                    {elem.contractId}
                                </Typography>
                                <Typography sx={{mt: 1.5}} color="text.secondary" variant="caption">
                                    {t('common.customer')}
                                </Typography>
                                <Typography variant="body2">
                                    {elem.customerName}
                                </Typography>
                                <Typography sx={{mt: 1.5}} color="text.secondary" variant="caption">
                                    {t('common.title')}
                                </Typography>
                                <Typography variant="body2">
                                    {elem.title}
                                </Typography>
                                {!elem.infinite && <>
                                    <Typography sx={{mt: 1.5}} color="text.secondary" variant="caption">
                                        {t('common.validUntil')}
                                    </Typography>
                                    <Typography variant="body2">
                                        {dayjs(elem.expire!.toString()).format("DD.MM.YYYY")}
                                    </Typography>
                                </>}

                                <Typography sx={{mt: 1.5}} color="text.secondary" variant="caption">
                                    {t('common.depositedAttachments')}
                                </Typography>
                                <List sx={{paddingRight: 2}}>
                                    {elem.supportCodes?.map(data => (
                                     
                                            <ListItem sx={{
                                                margin: 1,
                                                padding: 1,
                                                fontSize: 12,                                            }}
                                            secondaryAction={
                                                <>
                                                    <Tooltip title={`${t('common.supportRequestFor')} ${data.name}`}>                                   
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="support"
                                                        onClick={() => navigate(`/support?si=${Buffer.from(data.id!).toString('base64')}&qs=true`)}
                                                    >
                                                        <FontAwesomeIcon icon={faLifeRing} fontSize="md" />
                                                    </IconButton>
                                                    </Tooltip>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="devices"
                                                        onClick={() => navigate(`/deviceManagement/${data.id}`)}
                                                    >
                                                        <FontAwesomeIcon icon={faMobile} fontSize="md" />
                                                    </IconButton>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="devices"
                                                        onClick={() => navigate(`/licenses/${data.id}`)}
                                                    >
                                                        <FontAwesomeIcon icon={faFileCertificate} fontSize="md" />
                                                    </IconButton>
                                                </>
                                            }>
                                                <ListItemText style={{fontSize: 10}} primary={data.name} secondary={data.id}/>
                                            </ListItem>

                                    ))}
                                </List>

                                {context.userData!.userGroup! <= EUserGroup.OFFICE ?
                                    <>
                                        <Typography sx={{mt: 1.5}} color="text.secondary" variant="caption">
                                            {t('common.availableComponentUpdates')}
                                        </Typography>
                                        {elem.filterTags?.length === 0 ?
                                            <Typography variant="body2" color="error">{t('common.noComponents')}</Typography> :
                                            <List>
                                                {elem.filterTags?.map(value => {
                                                    return <ListItem style={{
                                                        margin: 2,
                                                        padding: 2,
                                                        fontSize: 12
                                                    }}><ListItemIcon style={{fontSize: 12}}><LabelImportant/></ListItemIcon><ListItemText
                                                        style={{fontSize: 12}}>{value.name}</ListItemText></ListItem>;
                                                })}
                                            </List>
                                        }
                                    </>
                                    :
                                    <>
                                        {(elem.status ?? 0) < 2 && <>
                                            <hr/>
                                            <Button fullWidth size={"small"} variant={"outlined"} color={"warning"}
                                                    style={{marginTop: 4, marginBottom: 4}}
                                                    onClick={renewContract(elem.contractId!, 1)}>{t('common.oneTimeActivation')} (1 {t('common.month')})</Button>
                                            <Button fullWidth size={"small"} variant={"outlined"} color={"primary"}
                                                    style={{marginTop: 4, marginBottom: 4}}
                                                    onClick={renewContract(elem.contractId!, 12)}>{t('common.extendContract12Months')}</Button>
                                            <Button fullWidth size={"small"} variant={"outlined"} color={"success"}
                                                    style={{marginTop: 4, marginBottom: 4}}
                                                    onClick={renewContract(elem.contractId!, 24)}>{t('common.extendContract24Months')}</Button>
                                        </>}</>
                                }

                            </CardContent>
                        </StyledCard>
                    )}
                </Masonry>
            </Box>
        </>
    );
}
