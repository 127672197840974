import {redirect, useNavigate, useParams, useSearchParams} from "react-router";
import React, {useEffect, useState} from "react";
import {useApplicationContext} from "../../ApplicationContext";
import {da} from "date-fns/locale";
import {GetServiceData} from "../../apiClient/Api";
import {Box, Button, FormControl, Paper, TextField, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Label} from "@mui/icons-material";


type ServiceCodeRouteParams = {
    si: string
    hw: string
    sw: string;
    st: string
    ts: string
    vf: string
    e: string
    f: string
}

export const ServiceCodeNew = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const context = useApplicationContext();
    const [passwordInput, setPasswordInput] = useState("");
    const theme = useTheme();
    const navigate = useNavigate();
    const redirect = () => {
        navigate(`/support?st=${passwordInput}`);
    }
    return <>
        <Box width={1}>
            <Paper>
                <Box width={0.8} marginLeft={"10%"} paddingTop={5} marginTop={3} paddingBottom={5}>
                    <Typography variant={"h5"}>Passwort Generator</Typography>
                    <Typography variant={"caption"}>Passwort</Typography>
                    <TextField value={passwordInput} onChange={(target) => {
                        setPasswordInput(target.target.value)
                    }}
                                onKeyDown={k => {
                                    if(k.key.toLowerCase() == "enter"){
                                        redirect()
                                    }
                                }}
                               margin="none" fullWidth
                               color={"primary"} sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.text.primary,
                        },
                    }}/>
                    <Button onClick={redirect}>Neues Passwort generieren</Button>
                </Box>
            </Paper>
        </Box>
    </>

}
